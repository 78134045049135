import CommunicationDesignerControlForm from "./CommunicationDesignerControlForm";
import FormLabel from "../../elements/FormLabel";
import classNames from "classnames";
import { ButtonStyle } from "../../../js/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArrowDown,
  ArrowUp,
  DeleteIcon,
  EditIcon,
} from "../../Icons";
import {
  checkIfDynamicContentItemSelected,
  generateUUID,
  getDynamicContentTitle,
} from "./Helpers";
import { Button, Card } from "../../elements/_Elements";

function CommunicationDesignerDynamicContentList({
  allowAdd,
  handleChange,
  handlePicklistChange,
  selectedDynamicContentItemIndex,
  setSelectedDynamicContentItemIndex,
  setEditingDynamicContentControl,
  setFieldValue,
  stages,
  state,
  values,
}) {
  const addNewDynamicContentItem = () => {
    let newIndex = values.dynamicContentItemsValuesArray.length;
    let newDynamicContentItem = {
      AttributeOperator: "=",
      AttributeValue1: values.attributeType === 0 ? "0" : "",
      AttributeValue1Caption: values.attributeType === 0 ? "No" : "",
      AttributeValue1Id: "",
      AttributeValue1Guid: "",
      AttributeValue2: "",
      UUID: generateUUID(),
      backgroundColour: values.backgroundColour,
      borderColour: values.borderColour,
      borderThickness: values.borderThickness,
      borderType: values.borderType,
      columns: values.columns,
      columnSplit: values.columnSplit,
      fontFamily: values.fontFamily,
      fontSize: values.fontSize,
      fontWeight: values.fontWeight,
      lineHeight: values.lineHeight,
      margin: values.margin,
      padding: values.padding,
      showOnMobile: values.showOnMobile,
      stackColumns: values.stackColumns,
      text1: values.text1,
      text2: values.text2,
      textAlign: values.textAlign,
      textColour: values.textColour,
    };
    let updatedDynamicContentItemArray = [
      ...values.dynamicContentItemsValuesArray,
      newDynamicContentItem,
    ];
    setFieldValue(
      "dynamicContentItemsValuesArray",
      updatedDynamicContentItemArray
    );
    setSelectedDynamicContentItemIndex(newIndex);
  };

  const moveUp = (index) => {
    let updatedDynamicContentItemArray =
      values.dynamicContentItemsValuesArray;
    var selected = updatedDynamicContentItemArray[index];
    updatedDynamicContentItemArray[index] =
      updatedDynamicContentItemArray[index - 1];
    updatedDynamicContentItemArray[index - 1] = selected;
    setFieldValue(
      "dynamicContentItemsValuesArray",
      updatedDynamicContentItemArray
    );
  };

  const moveDown = (index) => {
    let updatedDynamicContentItemArray =
      values.dynamicContentItemsValuesArray;
    var selected = updatedDynamicContentItemArray[index];
    updatedDynamicContentItemArray[index] =
      updatedDynamicContentItemArray[index + 1];
    updatedDynamicContentItemArray[index + 1] = selected;
    setFieldValue(
      "dynamicContentItemsValuesArray",
      updatedDynamicContentItemArray
    );
  };

  const removeDynamicContentItem = (index) => {
    let updatedDynamicContentItemArray = [];
    values.dynamicContentItemsValuesArray.forEach((d, i) => {
      if (i !== index) {
        updatedDynamicContentItemArray.push(d);
      }
    });
    setFieldValue(
      "dynamicContentItemsValuesArray",
      updatedDynamicContentItemArray
    );
  };

  return (
    <>
      {!selectedDynamicContentItemIndex &&
        selectedDynamicContentItemIndex !== 0 && (
          <>
            <div className="d-flex justify-content-between mb-3 align-items-center">
              <h5 className="m-0">Variants</h5>
              {allowAdd && (
                <Button
                  onClick={() => addNewDynamicContentItem()}
                  style={ButtonStyle.Link}
                  text={"Add"}
                  type={"button"}
                />
              )}
            </div>
            {allowAdd && (
              <>
                {values &&
                  values.dynamicContentItemsValuesArray &&
                  values.dynamicContentItemsValuesArray.length > 0 &&
                  values.dynamicContentItemsValuesArray.map(
                    (d, i) => {
                      return (
                        <Card className="flex-grow-1">
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              {getDynamicContentTitle(
                                d.AttributeOperator,
                                d.AttributeValue1,
                                d.AttributeValue1Caption,
                                d.AttributeValue2
                              )}
                            </div>
                            <div className="col-md-6 d-flex justify-content-between">
                              <Button
                                className={"btn-sm rounded-circle"}
                                style={ButtonStyle.Primary}
                                text={
                                  <FontAwesomeIcon icon={EditIcon} />
                                }
                                onClick={() => {
                                  setSelectedDynamicContentItemIndex(
                                    i
                                  );
                                  setEditingDynamicContentControl(
                                    true
                                  );
                                }}
                              />
                              <Button
                                className={"btn-sm rounded-circle"}
                                style={ButtonStyle.Info}
                                text={
                                  <FontAwesomeIcon icon={ArrowUp} />
                                }
                                onClick={() => {
                                  moveUp(i);
                                }}
                                disabled={
                                  values
                                    .dynamicContentItemsValuesArray
                                    .length === 1 || i === 0
                                }
                              />
                              <Button
                                className={"btn-sm rounded-circle"}
                                style={ButtonStyle.Info}
                                text={
                                  <FontAwesomeIcon icon={ArrowDown} />
                                }
                                onClick={() => {
                                  moveDown(i);
                                }}
                                disabled={
                                  values
                                    .dynamicContentItemsValuesArray
                                    .length === 1 ||
                                  i ===
                                    values
                                      .dynamicContentItemsValuesArray
                                      .length -
                                      1
                                }
                              />
                              <Button
                                className={"btn-sm rounded-circle"}
                                style={ButtonStyle.Secondary}
                                text={
                                  <FontAwesomeIcon
                                    icon={DeleteIcon}
                                  />
                                }
                                onClick={() => {
                                  removeDynamicContentItem(i);
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      );
                    }
                  )}
              </>
            )}
            <Card className="mb-3">
              <div className="row align-items-center">
                <div className="col-md-6">Default</div>
                <div className="col-md-6 d-flex justify-content-between align-items-center">
                  <Button
                    className={"btn-sm rounded-circle"}
                    style={ButtonStyle.Primary}
                    text={<FontAwesomeIcon icon={EditIcon} />}
                    onClick={() => {
                      setSelectedDynamicContentItemIndex("default");
                      setEditingDynamicContentControl(true);
                    }}
                  />
                  <div className="form-check m-0">
                    <FormLabel
                      label={"Hide"}
                      name={"hide"}
                      className={classNames(
                        "form-check-label",
                        "m-0"
                      )}
                    />
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="hide"
                      checked={values.hideDefaultContent}
                      onChange={(event) =>
                        setFieldValue(
                          "hideDefaultContent",
                          event.target.checked
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </Card>
          </>
        )}
      {(selectedDynamicContentItemIndex === "default" ||
        checkIfDynamicContentItemSelected(
          selectedDynamicContentItemIndex
        )) && (
        <CommunicationDesignerControlForm
          {...{
            handleChange,
            handlePicklistChange,
            setFieldValue,
            state,
            values,
          }}
          isEditingControl={true}
          removeDynamicContentItem={removeDynamicContentItem}
          selectedDynamicContentItemIndex={
            selectedDynamicContentItemIndex
          }
          setSelectedDynamicContentItemIndex={
            setSelectedDynamicContentItemIndex
          }
          setEditingDynamicContentControl={
            setEditingDynamicContentControl
          }
          stages={stages}
        />
      )}
    </>
  );
}

export default CommunicationDesignerDynamicContentList;
