import { useEffect, useState } from "react";
import { useFormReducer } from "../FormContext";
import {
  FormDatePicker,
  Input,
  Loading,
  SelectWithFiltering,
} from "../../elements/_Elements";
import { LoadingState, YesNo } from "../../../js/enums";
import moment from "moment";
import { isSameOrLaterDate } from "../../../js/utility";
import { useGlobalReducer } from "../../../GlobalContext";
import {
  GetAllEntityBasicInfo,
  GetEntityAttributes,
} from "../../../js/service";
import { DispatchMethods } from "../enums";
import { addToSessionStorage, getLookupInState } from "../Helpers";

function CommunicationDesignerDynamicContentAttributeValue({
  selectedDynamicContentItem,
  selectedDynamicContentItemIndex,
  setFieldValue,
  state,
  values,
}) {
  const [loadedData, setLoadedData] = useState(null);
  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [initialLoad, setInitialLoad] = useState(true);
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  useEffect(() => {
    const fetchLookupData = async () => {
      try {
        const lookupOptions = getLookupInState(state, {
          name: values.attributeTarget,
        });
        const [serviceResponse] = await Promise.all([
          lookupOptions &&
          lookupOptions.data &&
          lookupOptions.data.length > 0
            ? lookupOptions
            : GetAllEntityBasicInfo(
                globalDispatch,
                values.attributeTarget,
                null
              ),
        ]);
        if (serviceResponse && serviceResponse.data) {
          addToSessionStorage(
            values.attributeTarget,
            serviceResponse.data
          );
          dispatch({
            type: DispatchMethods.SetLookupOptions,
            lookupOptions: [
              {
                name: values.attributeTarget,
                data: serviceResponse.data,
              },
            ],
          });

          //Filter loaded data to not include data selected in the other dynamic content items associated
          //to the currently viewed comm control
          let filteredResult = serviceResponse.data;

          values.dynamicContentItemsValuesArray.forEach((d, i) => {
            if (i !== selectedDynamicContentItemIndex) {
              filteredResult = filteredResult.filter(
                (r) => r.Key !== d.AttributeValue1Guid
              );
            }
          });

          //If filtered result only has one result remaining, then auto update the field value to this value
          if (filteredResult.length === 1) {
            let updatedValues = values.dynamicContentItemsValuesArray;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1 = filteredResult[0].Value;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Caption = filteredResult[0].Value;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Id = `{${filteredResult[0].Key}}`;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Guid = filteredResult[0].Key;
            setFieldValue(
              "dynamicContentItemsValuesArray",
              updatedValues
            );
          }

          setLoadedData(filteredResult);
        } else {
          setLoadedData([]);
        }
        setLoading(LoadingState.Loaded);
        setInitialLoad(false);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEntityData = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetEntityAttributes(globalDispatch, values.entity),
        ]);
        let matchedAttribute = serviceResponse.data.find(
          (d) => d.DisplayName === values.attributeName
        );

        let matchedAttributeOptions =
          matchedAttribute && matchedAttribute.Options
            ? Object.values(matchedAttribute.Options)
            : [];
        if (matchedAttributeOptions.length > 0) {
          //Filter loaded data to not include data selected in the other dynamic content items associated
          //to the currently viewed comm control
          let filteredResult = matchedAttributeOptions[0];
          values.dynamicContentItemsValuesArray.forEach((d, i) => {
            if (i !== selectedDynamicContentItemIndex) {
              filteredResult = filteredResult.filter(
                (r) => r.DisplayName !== d.AttributeValue1
              );
            }
          });

          //If filtered result only has one result remaining, then auto update the field value to this value
          if (filteredResult.length === 1) {
            let updatedValues = values.dynamicContentItemsValuesArray;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1 = filteredResult[0].DisplayName;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Caption = filteredResult[0].DisplayName;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Id = null;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Guid = null;
            setFieldValue(
              "dynamicContentItemsValuesArray",
              updatedValues
            );
          }
          setLoadedData(filteredResult);
        } else {
          setLoadedData([]);
        }
        setLoading(LoadingState.Loaded);
        setInitialLoad(false);
      } catch (error) {
        console.error(error);
      }
    };

    //Only run the fetchLookupData if there is an attributeTarget set and the attributeType is lookup
    if (initialLoad) {
      if (values.attributeTarget && values.attributeType === 6) {
        fetchLookupData();
      }
      //Else run the fetchEntityData if there is an attribute and attributeName set
      //and an attributeType of picklist, state or status
      else if (
        values.attribute &&
        values.attributeName &&
        (values.attributeType === 11 ||
          values.attributeType === 12 ||
          values.attributeType === 13)
      ) {
        fetchEntityData();
      }
    }
  });

  const label = "Attribute Value";
  const name = "attribute-value";
  switch (values.attributeType) {
    case 0: //bool
      const getBoolOptions = () => {
        let options = Object.entries(YesNo).map(([Name, Number]) => ({
          Key: Number,
          Value: Name,
        }));
        values.dynamicContentItemsValuesArray.forEach((d, i) => {
          if (i !== selectedDynamicContentItemIndex) {
            options = options.filter(
              (r) => String(r.Key) !== String(d.AttributeValue1)
            );
          }
        });

        let updatedValues = values.dynamicContentItemsValuesArray;
        //Auto set value if only one option is remaining in the options
        if (
          options.length === 1 &&
          updatedValues[selectedDynamicContentItemIndex]
            .AttributeValue1 !== String(options[0].Key)
        ) {
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1 = String(options[0].Key);
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1Caption = options[0].Key ? "Yes" : "No";
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1Id = null;
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1Guid = null;
          setFieldValue(
            "dynamicContentItemsValuesArray",
            updatedValues
          );
        }
        //Clear value if there are no options
        else if (
          options.length === 0 &&
          updatedValues[selectedDynamicContentItemIndex]
            .AttributeValue1
        ) {
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1 = "";
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1Caption = "";
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1Id = null;
          updatedValues[
            selectedDynamicContentItemIndex
          ].AttributeValue1Guid = null;
          setFieldValue(
            "dynamicContentItemsValuesArray",
            updatedValues
          );
        }
        return options;
      };

      const options = getBoolOptions();
      if (options && options.length && options.length > 0) {
        return (
          <SelectWithFiltering
            label={label}
            name={name}
            onChange={(event) => {
              let updatedValues =
                values.dynamicContentItemsValuesArray;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1 = String(event.key);
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Caption = event.key ? "Yes" : "No";
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Id = null;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Guid = null;
              setFieldValue(
                "dynamicContentItemsValuesArray",
                updatedValues
              );
            }}
            isSearchable={true}
            mandatory={true}
            options={options}
            value={
              selectedDynamicContentItem.AttributeValue1 === "1"
                ? 1
                : 0
            }
          />
        );
      } else {
        return (
          <h5>
            No options found for the {values.attributeName} attribute
          </h5>
        );
      }
    case 2: //datetime
      return (
        <>
          <FormDatePicker
            dateFormat={"dd/MM/yyyy hh:mm aa"}
            handleDateChange={(date) => {
              let updatedValues =
                values.dynamicContentItemsValuesArray;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1 = moment(date).format(
                "DD/MM/YYYY HH:mm"
              );
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Caption = null;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Id = null;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Guid = null;
              setFieldValue(
                "dynamicContentItemsValuesArray",
                updatedValues
              );
            }}
            label={
              values.attributeOperator === "Between" ? label : "From"
            }
            locale={"en-GB"}
            mandatory={true}
            name={name}
            selected={
              selectedDynamicContentItem.AttributeValue1
                ? moment(
                    selectedDynamicContentItem.AttributeValue1,
                    "DD/MM/YYYY HH:mm"
                  ).toDate()
                : ""
            }
            showTimeSelect={true}
          />
          {selectedDynamicContentItem.AttributeOperator ===
            "Between" && (
            <FormDatePicker
              dateFormat={"dd/MM/yyyy hh:mm aa"}
              filterDate={(date) =>
                selectedDynamicContentItem.AttributeValue1
                  ? isSameOrLaterDate(
                      date,
                      moment(
                        selectedDynamicContentItem.AttributeValue1,
                        "DD/MM/YYYY HH:mm"
                      ).toDate()
                    )
                  : true
              }
              handleDateChange={(date) => {
                let updatedValues =
                  values.dynamicContentItemsValuesArray;
                updatedValues[
                  selectedDynamicContentItemIndex
                ].AttributeValue2 = moment(date).format(
                  "DD/MM/YYYY HH:mm"
                );
                updatedValues[
                  selectedDynamicContentItemIndex
                ].AttributeValue2Caption = null;
                setFieldValue(
                  "dynamicContentItemsValuesArray",
                  updatedValues
                );
              }}
              label={"To"}
              locale={"en-GB"}
              mandatory={true}
              name={name}
              selected={
                selectedDynamicContentItem.AttributeValue2
                  ? moment(
                      selectedDynamicContentItem.AttributeValue2,
                      "DD/MM/YYYY HH:mm"
                    ).toDate()
                  : ""
              }
              showTimeSelect={true}
            />
          )}
        </>
      );
    case 3: //decimal
    case 4: //double
    case 5: //int
    case 8: //money
      const inputType =
        values.attributeType === 3
          ? "decimal"
          : values.attributeType === 4
          ? "float"
          : values.attributeType === 8
          ? "money"
          : "int";
      return (
        <>
          <Input
            label={label}
            name={name}
            mandatory={true}
            onChange={(event) => {
              let updatedValues =
                values.dynamicContentItemsValuesArray;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1 = String(event.target.value);
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Caption = null;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Id = null;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Guid = null;
              setFieldValue(
                "dynamicContentItemsValuesArray",
                updatedValues
              );
            }}
            type={inputType}
            value={selectedDynamicContentItem.AttributeValue1}
          />
          {selectedDynamicContentItem.AttributeOperator ===
            "Between" && (
            <Input
              label={label}
              name={name}
              mandatory={true}
              onChange={(event) => {
                let updatedValues =
                  values.dynamicContentItemsValuesArray;
                updatedValues[
                  selectedDynamicContentItemIndex
                ].AttributeValue2 = String(event.target.value);
                setFieldValue(
                  "dynamicContentItemsValuesArray",
                  updatedValues
                );
              }}
              type={inputType}
              value={selectedDynamicContentItem.AttributeValue2}
            />
          )}
        </>
      );
    case 6: //Lookup
      if (loading !== LoadingState.Loaded) {
        return <Loading />;
      }
      if (loadedData && loadedData.length && loadedData.length > 0) {
        return (
          <SelectWithFiltering
            label={label}
            name={name}
            onChange={(event) => {
              let updatedValues =
                values.dynamicContentItemsValuesArray;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1 = event.label;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Caption = event.label;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Id = `{${event.key}}`;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Guid = event.key;
              setFieldValue(
                "dynamicContentItemsValuesArray",
                updatedValues
              );
            }}
            isSearchable={true}
            mandatory={true}
            options={loadedData}
            value={selectedDynamicContentItem.AttributeValue1Guid}
          />
        );
      } else {
        return (
          <h5>
            No records found for the {values.attributeName} attribute
          </h5>
        );
      }
    case 7: //text
    case 14: //nvarchar
      return (
        <Input
          label={label}
          name={name}
          mandatory={true}
          onChange={(event) => {
            let updatedValues = values.dynamicContentItemsValuesArray;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1 = String(event.target.value);
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Caption = null;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Id = null;
            updatedValues[
              selectedDynamicContentItemIndex
            ].AttributeValue1Guid = null;
            setFieldValue(
              "dynamicContentItemsValuesArray",
              updatedValues
            );
          }}
          type={"text"}
          value={selectedDynamicContentItem.AttributeValue1}
        />
      );
    case 11: //Picklist
    case 12: //State
    case 13: //Status
      if (loading !== LoadingState.Loaded) {
        return <Loading />;
      }
      if (loadedData && loadedData.length && loadedData.length > 0) {
        return (
          <SelectWithFiltering
            label={label}
            name={name}
            onChange={(event) => {
              let updatedValues =
                values.dynamicContentItemsValuesArray;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1 = event.label;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Caption = event.label;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Id = null;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeValue1Guid = null;
              setFieldValue(
                "dynamicContentItemsValuesArray",
                updatedValues
              );
            }}
            isSearchable={true}
            mandatory={true}
            options={loadedData.map((l) => {
              return { Key: l.DisplayName, Value: l.DisplayName };
            })}
            value={selectedDynamicContentItem.AttributeValue1}
          />
        );
      } else {
        return (
          <h5>
            No options found for the {values.attributeName} attribute
          </h5>
        );
      }
    default:
      return <></>;
  }
}

export default CommunicationDesignerDynamicContentAttributeValue;
