import EditLink from "./EditLink";

function ReadOnlyLabel({ field, label, state, value }) {
  if (!label && !value) {
    return <></>;
  }

  return (
    <>
      <p className="fw-bold m-0">{label}</p>
      {field &&
      field.linkedEntityId &&
      field.linkForNewRecord &&
      state &&
      state[field.linkedEntityId] ? (
        <p>
          <EditLink
            id={state[field.linkedEntityId]}
            linkForNewRecord={field.linkForNewRecord}
            openNewTab={true}
            text={state[field.name] !== null ? state[field.name]:"(No name)"}
          />
        </p>
      ) : (
        <>
          {field && field.type === "textarea" ? (
            <>{value || ""}</>
          ) : (
            <p>{value || ""}</p>
          )}
        </>
      )}
    </>
  );
}

export default ReadOnlyLabel;
