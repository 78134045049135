import lookups from "./lookups";
import { PastYearsDescendingSelectHeader } from "../components/elements/DateSelectHeader";
import { CommunicationActivityFormStages } from "./communicationActivity";

export const CommunicationActivitiesFormStages = [
  {
    title: "Communication Activities",
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          submitSearchOnLoad: true,
          useSubmitButton: true,
          fields: [
            {
              name: "subject",
              className: "col-name",
            },
            {
              name: "regardingobjectidname",
              className: "col-name",
              linkedEntityId: "regardingobjectid",
              linkedEntityName: "regardingobjectentityname",
            },
            {
              name: "g4c_recipientcontactname",
              className: "col-name",
              linkedEntityId: "g4c_recipientcontact",
              linkForNewRecord: "/contact",
            },
            {
              name: "g4c_test",
              className: "col-bool",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4c_communicationname",
              type: "text",
            },
            {
              name: "subject",
              type: "text",
            },
            {
              name: "regardingobjectidname",
              type: "text",
            },
            {
              name: "g4c_recipientcontactname",
              type: "text",
            },
            {
              name: "g4c_test",
              label: "Include test communications",
              type: "bit",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsDescendingSelectHeader,
              type: "singledatetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/communication-activity",
          subFormStages: CommunicationActivityFormStages,
          lookup: lookups.g4c_communicationactivity.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
