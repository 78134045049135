import { Fragment } from "react";
import { Alert, DroppableElement } from "../../elements/_Elements";
import { AlertStyle } from "../../../js/enums";
import Rule from "./Rule";

function RulesAndCount({
  activeId,
  errors,
  rulesData,
  setFieldValue,
  values,
}) {
  const { rule_data } = values;
  
  return (
    <>
      {rule_data.length === 0 ? (
        <>
        <DroppableElement id="rule-0" className="rounded">
        <Alert
            className="m-0"
            style={AlertStyle.Warning}
            text="There are no rules defined. Drag a new rule here."
          />
        </DroppableElement>
        {errors && errors["norule"] === true &&  
        <Alert 
          className="m-0 text-center mt-1"
          style={AlertStyle.Danger}
          text="There are no rules defined."
         />
        }
        </>
      ) : (
        <>
          <DroppableElement id="rule-0" className="mb-3" />
          {rule_data.map((rule, index) => {
            return (
              <Fragment key={index}>
                <div className="mb-3">
                  <Rule
                    {...{
                      activeId,
                      errors,
                      index,
                      rule,
                      rulesData,
                      setFieldValue,
                      values,
                    }}
                  />
                </div>
                <DroppableElement
                  id={`rule-${index + 1}`}
                  className="mb-3"
                />
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
}

export default RulesAndCount;
