export const CommunicationActivityFormStages = [
  {
    title: "Communication Details",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "subject",
            description: "",
            label: "Subject",
            type: "nvarchar",
          },
          {
            name: "g4c_communicationname",
            description: "",
            label: "Communication",
            type: "nvarchar",
          },
          {
            name: "g4c_test",
            description: "",
            label: "Is test?",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Recipient",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_recipientcontactname",
            description: "",
            label: "Recipient (Contact)",
            linkedEntityId: "g4c_recipientcontact",
            linkForNewRecord: "/contact",
            type: "nvarchar",
          },
          {
            name: "regardingobjectidname",
            description: "",
            label: "Regarding",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
