import { useState } from "react";
import { useFormReducer } from "../FormContext";
import { Tabs } from "../../elements/_Elements";
import SidebarForm from "../SidebarForm";
import { DesignMode } from "./enums";
import {
  changeDesignMode,
  checkIfDynamicContentItemSelected,
} from "./Helpers";
import CommunicationDesignerDynamicContentSettings from "./CommunicationDesignerDynamicContentSettings";

function CommunicationDesignerControlForm({
  handleChange,
  handlePicklistChange,
  isEditingControl,
  removeDynamicContentItem,
  saveUndoSnapshot,
  selectedDynamicContentItemIndex,
  setEditingDynamicContentControl,
  setFieldValue,
  setSelectedDynamicContentItemIndex,
  stages,
  state,
  values,
}) {
  const dispatch = useFormReducer();

  const [activeTab, setActiveTab] = useState(stages[0].title);

  const filteredStages = stages.filter((s) =>
    s.displayIfFromValues ? s.displayIfFromValues(values) : true
  );

  //saveUndoSnapshot should be null when we are editing a control.
  //We don't want to call saveUndoSnapshot when editing a control because
  //we only want to save the snapshot when apply and close is clicked
  const handleChangeValue = (event, name) => {
    if (!isEditingControl && saveUndoSnapshot) {
      saveUndoSnapshot(values);
    }
    if (
      checkIfDynamicContentItemSelected(
        selectedDynamicContentItemIndex
      )
    ) {
      //Dynamic content
      let updatedValues = values.dynamicContentItemsValuesArray;
      updatedValues[selectedDynamicContentItemIndex][name] =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      setFieldValue("dynamicContentItemsValuesArray", updatedValues);
    } else {
      handleChange(event);
    }
  };

  const handlePicklistChangeValue = (event, name) => {
    if (!isEditingControl && saveUndoSnapshot) {
      saveUndoSnapshot(values);
    }

    if (
      checkIfDynamicContentItemSelected(
        selectedDynamicContentItemIndex
      )
    ) {
      //Dynamic content
      let updatedValues = values.dynamicContentItemsValuesArray;
      updatedValues[selectedDynamicContentItemIndex][name] =
        event.target.value;
      setFieldValue("dynamicContentItemsValuesArray", updatedValues);
    } else {
      handlePicklistChange(event);
    }
  };

  const changeTab = (tab) => {
    setActiveTab(tab.title);
    // change the design mode if the user clicked on Controls or Rows
    if (tab.title === "Controls") {
      changeDesignMode(dispatch, DesignMode.Controls, values);
    } else if (tab.title === "Rows") {
      changeDesignMode(dispatch, DesignMode.Rows, values);
    }
  };

  return (
    <>
      {selectedDynamicContentItemIndex !== "" &&
        setSelectedDynamicContentItemIndex && (
          <CommunicationDesignerDynamicContentSettings
            removeDynamicContentItem={removeDynamicContentItem}
            selectedDynamicContentItemIndex={
              selectedDynamicContentItemIndex
            }
            setEditingDynamicContentControl={
              setEditingDynamicContentControl
            }
            setFieldValue={setFieldValue}
            setSelectedDynamicContentItemIndex={
              setSelectedDynamicContentItemIndex
            }
            state={state}
            values={values}
          />
        )}
      <Tabs
        activeTab={activeTab}
        onClick={(tab) => changeTab(tab)}
        tabs={filteredStages}
      />
      <div className="sidebar-content position-relative flex-fill my-3">
        <SidebarForm
          {...{
            activeTab,
            setFieldValue,
            state,
            values,
          }}
          handleChange={(event, name) => {
            handleChangeValue(event, name);
          }}
          handleFilteredPicklistChange={(event, _, name) => {
            const value = event ? event.value : "";
            if (
              checkIfDynamicContentItemSelected(
                selectedDynamicContentItemIndex
              )
            ) {
              //Dynamic content
              let updatedValues =
                values.dynamicContentItemsValuesArray;
              updatedValues[selectedDynamicContentItemIndex][name] =
                value;
              setFieldValue(
                "dynamicContentItemsValuesArray",
                updatedValues
              );
            } else {
              setFieldValue(name, value);
            }
          }}
          handlePicklistChange={(event, name) => {
            handlePicklistChangeValue(event, name);
          }}
          selectedDynamicContentItemIndex={
            selectedDynamicContentItemIndex
          }
          stages={filteredStages}
          useTabs={true}
        />
      </div>
    </>
  );
}

export default CommunicationDesignerControlForm;
