import lookups from "./lookups";
import { BookingFormStages } from "./booking";
import { BookingStatus, DateTimePickerRangesAll } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const BookingsFormStages = [
  {
    title: "Bookings",
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          submitSearchOnLoad: true,
          useSubmitButton: true,
          fields: [
            {
              name: "g4b_bookingreference",
              className: "col-name",
            },
            {
              name: "g4b_bookingstatus",
              className: "col-name",
              type: "statusbadge",
              enum: BookingStatus,
            },
            {
              name: "g4b_channelidname",
              className: "col-short-name",
            },
            {
              name: "bookingtotalprice",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_discountvalue",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_bookingreference",
              type: "text",
            },
            {
              name: "g4b_bookingstatus",
              defaultValue: [
                BookingStatus.Active,
                BookingStatus.Reserved,
              ],
              enum: BookingStatus,
              type: "select",
            },
            {
              name: "g4b_channelid",
              label: "Channel",
              lookup: lookups.g4b_channel.all,
              type: "select",
            },
            {
              name: "createdon",
              dateTimeDefaultRangeType: DateTimePickerRangesAll.Today,
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          filterDescription:
            "When searching by booking reference, all other filters will be ignored.",
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/booking",
          subFormStages: BookingFormStages,
          lookup: lookups.g4b_booking.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
