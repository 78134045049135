import { Button } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";
import RuleFormStages from "./RuleFormStages";
import SidebarForm from "../SidebarForm";
import { applyRuleChanges, cancelRuleChanges } from "./Helpers";
import { useFormReducer } from "../FormContext";

function EditRule({
  handleBlur,
  handleChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  rulesData,
  setFieldValue,
  state,
  values,
}) {
  const dispatch = useFormReducer();

  // create a new values object with existing values combined with the rule data
  const ruleValues = {
    ...values,
    rules: rulesData,
  };

  return (
    <>
      <div className="sidebar-content d-flex justify-content-end mb-3">
        <Button
          text="Cancel"
          onClick={() => cancelRuleChanges(dispatch, values)}
          style={ButtonStyle.Link}
        />
      </div>
      <div className="sidebar-content position-relative flex-fill my-3">
        <SidebarForm
          {...{
            handleBlur,
            handleChange,
            handleFilteredPicklistChange,
            handlePicklistChange,
            setFieldValue,
            state,
          }}
          stages={RuleFormStages}
          useTabs={false}
          values={ruleValues}
        />
      </div>
      <div className="d-flex justify-content-end flex-shrink-0 pt-3">
        <Button
          text="Apply and close"
          onClick={() => {
            applyRuleChanges(dispatch, rulesData, state, values);
          }}
          style={ButtonStyle.Primary}
        />
      </div>
    </>
  );
}

export default EditRule;
