import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../GlobalContext";
import {
  SetRecordStatus,
  checkForValidRoles,
  displayElement,
  getFieldLabelForFormTable,
  searchRecordsAndAddToLoadedData,
  sortColumn,
  updateBrowserHistoryWithFilters,
} from "./Helpers";
import {
  AlphabetPagination,
  EditLink,
  Loading,
  Pagination,
  SetStatusModal,
} from "../elements/_Elements";
import {
  displayFieldValue,
  convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue,
  getPageForEntity,
} from "../../js/utility";
import { RecordStatus } from "../../js/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowDown, ArrowUp } from "../Icons";
import FilterSection from "./FilterSection";
import FormTableSubmitActions from "./TableActions";
import RecordsPerPage from "./RecordsPerPage";
import "./FormTable.scss";

function FormTableSubmit({
  dispatch,
  disabled = false,
  initialFilters,
  parentId,
  section,
  state,
  values,
}) {
  const globalDispatch = useGlobalReducer();

  const { table } = section;
  const {
    allowAdd,
    allowAddRoles,
    allowEdit,
    allowStatusEdit,
    fields,
    filterByStatus,
    filterDescription,
    hideNameColumn,
    loadStateRelatedEntityName,
    lookup,
    statusEditAllowedRoles,
    submitSearchOnLoad,
    subFormStages,
    useAlphabetPagination,
  } = table;

  const globalState = useGlobalState();
  const filteredTableFields = fields.filter((field) =>
    displayElement(field, state, values, globalState)
  );

  //If the table doesn't have a statusEditAllowedRoles value set then allow for
  //any role to edit the status. If it does have a value then check if the logged in
  //user has a valid role which will allow them to edit the record status
  const statusEditAllowed =
    allowStatusEdit &&
    (!statusEditAllowedRoles ||
      (statusEditAllowedRoles &&
        checkForValidRoles(statusEditAllowedRoles, globalState)));

  const entityName = lookup ? lookup.entityName : null;

  const initialOrderedBy =
    typeof table.initialOrderedBy !== "undefined"
      ? table.initialOrderedBy
      : "Name";
  const initialOrderedByAsc =
    typeof table.initialOrderedByAsc !== "undefined"
      ? table.initialOrderedByAsc
      : true;

  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [loadedRecords, setLoadedRecords] = useState([]);
  const [letter, setLetter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [initialLoad, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderedBy, setOrderedBy] = useState(initialOrderedBy);
  const [orderedByAsc, setOrderedByAsc] = useState(
    initialOrderedByAsc
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsSelected, setRecordsSelected] = useState([]);
  const [
    showConfirmStatusChangeModal,
    setShowConfirmStatusChangeModal,
  ] = useState(false);
  const [recordStatusToShow, setRecordStatusToShow] = useState(
    RecordStatus.Active
  );

  const updateRecordsOrder = async (orderByProperty) => {
    const newOrderedByAscValue =
      orderByProperty === orderedBy ? !orderedByAsc : true;
    setOrderedBy(orderByProperty);
    setOrderedByAsc(newOrderedByAscValue);

    //Don't rerun the call to handleSubmitFilter if we have a total record count thats less
    //than the recordsPerPage value as we already have all of the records loaded
    if (totalRecords > recordsPerPage) {
      //No need to do sortColumn as handleSubmitFilter will return the data in its order
      //We are calling this as we don't have all the data loaded for the ordering so we
      //need to get the data back in its ordering
      await handleSubmitFilter(
        1,
        orderByProperty,
        newOrderedByAscValue,
        recordsPerPage,
        true
      );
    } else {
      const newOrderedList = sortColumn(
        loadedRecords,
        orderByProperty,
        newOrderedByAscValue
      );

      setLoadedRecords(newOrderedList);
    }

    setCurrentPage(1);
    setOrderedBy(orderByProperty);
    setOrderedByAsc(newOrderedByAscValue);
  };

  const setCurrentPageValue = async (newPage) => {
    //Need to check whether the page we are going to has already got data loaded on it
    //If it doesn't then run the ticketing call to retrieve it.
    const indexOfLastRecord = newPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let filteredData = loadedRecords.filter(
      (r) =>
        r.Fields.row &&
        r.Fields.row > indexOfFirstRecord &&
        r.Fields.row <= indexOfLastRecord
    );
    if (filteredData.length === 0) {
      await handleSubmitFilter(
        newPage,
        orderedBy,
        orderedByAsc,
        recordsPerPage,
        false
      );
    }
    setCurrentPage(newPage);
  };

  const setRecordsPerPageValue = async (newRecordsPerPage) => {
    //Refresh the table with the new number of records per page
    await handleSubmitFilter(
      1,
      orderedBy,
      orderedByAsc,
      newRecordsPerPage,
      true
    );
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1);
  };

  const handleSubmitFilter = useCallback(
    async (
      pageNumber,
      fieldOrderedBy,
      fieldOrderedByAsc,
      recordsToDisplay,
      resetLoadedRecords
    ) => {
      let filterObject = {};
      let filterArray = [];
      setIsSubmitting(true);
      table.filters.forEach((filter) => {
        if (
          filter.type === "datetime" ||
          filter.type === "singledatetime"
        ) {
          if (filter.type === "singledatetime") {
            let dateName = filter.name + "-Date";
            let dateKey = filters.find((f) => f.Key === dateName);
            let dateValue = "";
            if (dateKey) {
              dateValue =
                convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue(
                  dateKey.Value
                );
            }
            filterObject[dateName] = dateValue;
            filterArray.push({
              Key: dateName,
              FilterAction: filter.filterAction,
              DateTimeRange: dateKey.DateTimeRange,
              Type: "datetime",
              Value: dateKey.Value,
            });
          }

          let fromName = filter.name + "-From";
          let fromKey = filters.find((f) => f.Key === fromName);
          let fromValue = "";
          if (fromKey) {
            fromValue =
              convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue(
                fromKey.Value
              );
          }
          filterObject[fromName] = fromValue;
          filterArray.push({
            Key: fromName,
            FilterAction: filter.filterAction,
            DateTimeRange: fromKey.DateTimeRange,
            Type: "datetime",
            Value: fromKey.Value,
          });

          let toName = filter.name + "-To";
          let toKey = filters.find((f) => f.Key === toName);
          let toValue = "";
          if (toKey) {
            toValue =
              convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue(
                toKey.Value
              );
          }
          filterObject[toName] = toValue;
          filterArray.push({
            Key: toName,
            FilterAction: filter.filterAction,
            DateTimeRange: toKey.DateTimeRange,
            Type: "datetime",
            Value: toKey.Value,
          });
        }
        if (filter.type === "select") {
          let key = filters.filter((f) => f.Key === filter.name);
          let value = "";
          if (key.length === 1 && key[0].Value) {
            value = key[0].Value.toString().toLowerCase();
          }
          filterObject[filter.name] = value;
          filterArray.push({
            Key: filter.name,
            FilterAction: filter.filterAction,
            TextFieldsSearch: filter.textFieldsSearch,
            Type: "select",
            Value: key[0].Value,
          });
        } else {
          let key = filters.filter((f) => f.Key === filter.name);
          let value = "";
          if (key.length === 1) {
            value = key[0].Value;
          }
          filterObject[filter.name] = value;
          filterArray.push({
            Key: filter.name,
            FilterAction: filter.filterAction,
            Type: filter.type,
            Value: value,
          });
        }
      });

      //Add in the parent Id just in case this is a table within a loaded up record
      //and the services needs to use that ID to obtain the records for that ID
      filterArray.push({
        Key: "parentId",
        Value: state.id,
      });
      filterObject["parentId"] = state.id;

      filterObject["fieldOrderedBy"] = fieldOrderedBy;
      filterObject["fieldOrderedByAsc"] = fieldOrderedByAsc;

      let getInactiveRecords =
        filterByStatus &&
        filters.some((f) => f.Key === "getInactive") &&
        filters.filter((f) => f.Key === "getInactive")[0].Value;

      setRecordStatusToShow(
        getInactiveRecords
          ? RecordStatus.Inactive
          : RecordStatus.Active
      );

      filterArray.push({
        Key: "getInactive",
        Value: getInactiveRecords,
      });
      filterObject["getInactive"] = getInactiveRecords;

      setFilters(filterArray);
      setRecordsSelected([]);
      updateBrowserHistoryWithFilters(entityName, filterArray);
      searchRecordsAndAddToLoadedData(
        table.lookup && table.lookup.entityName
          ? table.lookup.entityName
          : entityName,
        filterObject,
        initialLoad ? null : globalDispatch,
        loadedRecords,
        resetLoadedRecords,
        fieldOrderedBy,
        fieldOrderedByAsc,
        setLoadedRecords,
        setLoading,
        setIsSubmitting,
        setTotalRecords,
        pageNumber,
        recordsToDisplay
      );
    },
    [
      table.filters,
      table.lookup,
      state.id,
      filterByStatus,
      filters,
      entityName,
      initialLoad,
      globalDispatch,
      loadedRecords,
    ]
  );

  useEffect(() => {
    if (initialLoad) {
      if (submitSearchOnLoad) {
        handleSubmitFilter(
          1,
          orderedBy,
          orderedByAsc,
          recordsPerPage,
          true
        );
      } else {
        setLoading(false);
      }
    }
    setInitialLoading(false);
  }, [
    globalDispatch,
    initialLoad,
    state.id,
    values.updateAction,
    handleSubmitFilter,
    submitSearchOnLoad,
    orderedBy,
    orderedByAsc,
    recordsPerPage,
  ]);

  const toggleCheck = (record) => {
    if (recordsSelected.some((m) => m.Id === record.Id)) {
      //Uncheck box
      setRecordsSelected(
        recordsSelected.filter((m) => m.Id !== record.Id)
      );
    } else {
      //Check box
      setRecordsSelected([...recordsSelected, record]);
    }
  };

  const handleRecordStatusChange = () => {
    setIsSubmitting(true);
    SetRecordStatus(
      dispatch,
      table.lookup && table.lookup.entityName
        ? table.lookup.entityName
        : entityName,
      recordsSelected.map((r) => r.Id),
      String(recordStatusToShow) === String(RecordStatus.Inactive)
        ? 0
        : 1,
      globalDispatch,
      true,
      null,
      setCurrentPage,
      setCurrentRecords,
      null,
      null,
      setRecordsSelected,
      setLoading,
      setIsSubmitting,
      setLoadedRecords,
      handleSubmitFilter,
      orderedBy,
      orderedByAsc,
      recordsPerPage,
      state
    );
    setShowConfirmStatusChangeModal(false);
  };

  if (loading) {
    return <Loading />;
  }
  const toggleAllowAdd =
    !disabled &&
    allowAdd &&
    checkForValidRoles(allowAddRoles, globalState);
  const toggleAllowEdit = !disabled && allowEdit;

  return (
    <>
      {showConfirmStatusChangeModal && (
        <SetStatusModal
          handleRecordStatusChange={handleRecordStatusChange}
          recordStatusToShow={recordStatusToShow}
          setShowConfirmStatusChangeModal={
            setShowConfirmStatusChangeModal
          }
        />
      )}
      {section.name && <h5>{section.name}</h5>}
      <div className="mb-3">
        <div className="mb-3">
          <FilterSection
            {...{
              disabled,
              filterDescription,
              filters,
              filterByStatus,
              handleSubmitFilter,
              isSubmitting,
              loadedRecords,
              orderedBy,
              orderedByAsc,
              recordsPerPage,
              setCurrentPage,
              setFilters,
              state,
              subFormStages,
              table,
              values,
            }}
            displayIcons={false}
          />
          {(toggleAllowAdd || statusEditAllowed) && (
            <FormTableSubmitActions
              {...{
                filters,
                isSubmitting,
                parentId,
                recordsSelected,
                recordStatusToShow,
                setShowConfirmStatusChangeModal,
                statusEditAllowed,
                table,
                toggleAllowAdd,
              }}
            />
          )}
        </div>
        <div className="list-view">
          <div className={"table-responsive"}>
            <table className="table table-hover mb-0">
              <thead>
                <tr className="table-info">
                  {statusEditAllowed && (
                    <th className={classNames("col-select")} />
                  )}
                  {!hideNameColumn && (
                    <th
                      scope="col"
                      className={classNames(
                        "col-primary",
                        orderedBy === "Name" ? "ordered" : ""
                      )}
                      onClick={() => updateRecordsOrder("Name")}
                    >
                      Name
                      {orderedBy === "Name" ? (
                        orderedByAsc ? (
                          <FontAwesomeIcon
                            className="cursor-pointer ms-2"
                            icon={ArrowUp}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="cursor-pointer ms-2"
                            icon={ArrowDown}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                  )}
                  {filteredTableFields.map((field, i) => {
                    return (
                      <th
                        key={i}
                        scope="col"
                        className={classNames(
                          field.className,
                          orderedBy === field.name ? "ordered" : ""
                        )}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <span
                            className="me-auto"
                            onClick={() =>
                              updateRecordsOrder(field.name)
                            }
                          >
                            {getFieldLabelForFormTable(
                              field,
                              subFormStages
                            )}
                            {orderedBy === field.name ? (
                              orderedByAsc ? (
                                <FontAwesomeIcon
                                  className="cursor-pointer ms-2"
                                  icon={ArrowUp}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="cursor-pointer ms-2"
                                  icon={ArrowDown}
                                />
                              )
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {loadedRecords && loadedRecords.length > 0 && (
                <tbody>
                  {currentRecords.map((record, i) => {
                    const highlightRecord =
                      state.highlightRelatedEntities
                        ? state.highlightRelatedEntities.find(
                            (x) =>
                              x.entityName ===
                                loadStateRelatedEntityName &&
                              x.id === record.Id
                          )
                        : false;

                    return (
                      <tr
                        key={i}
                        className={classNames(
                          highlightRecord ? "table-success" : ""
                        )}
                      >
                        {statusEditAllowed && (
                          <td
                            className="text-center"
                            onClick={() => toggleCheck(record)}
                          >
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              name={"record" + i}
                              checked={recordsSelected.some(
                                (m) => m.Id === record.Id
                              )}
                            />
                          </td>
                        )}
                        {!hideNameColumn && (
                          <th scope="row">
                            {toggleAllowEdit &&
                            table.linkForNewRecord ? (
                              <EditLink
                                id={record.Id}
                                linkForNewRecord={
                                  table.linkForNewRecord
                                }
                                text={record.Name}
                              />
                            ) : (
                              <span>
                                {record.Name ? record.Name : ""}
                              </span>
                            )}
                          </th>
                        )}
                        {filteredTableFields.map((field, j) => {
                          const type = field.type ? field.type : "";
                          const fieldValue = displayFieldValue(
                            record.Fields[field.name],
                            type,
                            field,
                            record,
                            state
                          );

                          if (
                            j === 0 &&
                            hideNameColumn &&
                            toggleAllowEdit
                          ) {
                            return (
                              <th key={j} scope="row">
                                {table.linkForNewRecord ? (
                                  <EditLink
                                    className="d-block text-decoration-none"
                                    id={record.Id}
                                    linkForNewRecord={
                                      table.linkForNewRecord
                                    }
                                    text={
                                      fieldValue ? fieldValue : "-"
                                    }
                                  />
                                ) : (
                                  <span>{fieldValue}</span>
                                )}
                              </th>
                            );
                          } else {
                            let recordId =
                              field.linkedEntityId &&
                              (field.linkForNewRecord ||
                                field.linkedEntityName)
                                ? record.Fields[field.linkedEntityId]
                                : null;
                            if (recordId && recordId.Id) {
                              recordId = recordId.Id;
                            }

                            return (
                              <td key={j}>
                                {field.linkedEntityId &&
                                (field.linkForNewRecord ||
                                  field.linkedEntityName) ? (
                                  <EditLink
                                    id={recordId}
                                    linkForNewRecord={
                                      field.linkedEntityName
                                        ? getPageForEntity(
                                            record.Fields[
                                              field.linkedEntityName
                                            ]
                                          )
                                        : field.linkForNewRecord
                                    }
                                    text={fieldValue}
                                  />
                                ) : (
                                  <span>{fieldValue}</span>
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {(!loadedRecords || loadedRecords.length === 0) && (
              <div className="col-12 mt-5">
                <p className="text-center">
                  {
                    "Please change the filtering, then press the submit button"
                  }
                </p>
              </div>
            )}
          </div>
          <div className="d-flex align-items-end mt-3">
            <RecordsPerPage
              {...{
                recordsPerPage,
              }}
              records={loadedRecords}
              setRecordsPerPage={setRecordsPerPageValue}
              totalRecordCount={totalRecords}
            />
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPageValue}
              records={loadedRecords}
              recordsPerPage={recordsPerPage}
              setRecords={setCurrentRecords}
              totalPages={
                totalRecords > loadedRecords.length
                  ? Math.ceil(totalRecords / recordsPerPage)
                  : null
              }
              useRows={true}
            />
          </div>
        </div>
      </div>
      {useAlphabetPagination &&
        loadedRecords &&
        loadedRecords.length > recordsPerPage && (
          <div className="mt-auto">
            <AlphabetPagination
              currentLetter={letter}
              onSelectLetter={setLetter}
            />
          </div>
        )}
    </>
  );
}

export default FormTableSubmit;
