import React, { useState } from "react";
import {
  AudienceType,
  ButtonStyle,
  LoadingState,
} from "../../../js/enums";
import { Button, Input, Loading } from "../../elements/_Elements";
import {
  applyControlChanges,
  cancelControlChanges,
  getStagesForControl,
} from "./Helpers";
import { useFormReducer } from "../FormContext";
import GlobalFormStages from "./GlobalStages";
import CommunicationDesignerControlForm from "./CommunicationDesignerControlForm";
import { useLookupOptions } from "../Helpers";
import { useGlobalReducer } from "../../../GlobalContext";
import lookups from "../../../forms/lookups";
import {
  getEntityNameForRegarding,
  isRegardingDifferentToListType,
} from "../../../js/utility";
import CommunicationDesignerDynamicContentForm from "./CommunicationDesignerDynamicContentForm";

function getLookups(audienceType, listType, regardingType) {
  const entityLookup =
    listType === "1"
      ? lookups.account.attributes
      : lookups.contact.attributes;

  const includeRegardingType = isRegardingDifferentToListType(
    listType,
    regardingType
  );

  if (includeRegardingType) {
    const regardingEntityName =
      audienceType === AudienceType["A/B Testing"]
        ? "contact"
        : getEntityNameForRegarding(regardingType);
    return [entityLookup, lookups[regardingEntityName].attributes];
  }

  return [entityLookup];
}

function getStages(values) {
  if (values.editControl) {
    const { columnIndex, controlIndex, rowIndex, template } =
      values.editControl;
    const properties =
      values.rows[rowIndex].Columns[columnIndex].Controls[
        controlIndex
      ].Properties;

    const propertiesObject = properties.reduce((o, property) => {
      o[property.Name] = property.Value;
      return o;
    }, {});

    return getStagesForControl(template, propertiesObject);
  }
  return GlobalFormStages;
}

function CommunicationDesignerSidebar({
  handleChange,
  handlePicklistChange,
  saveUndoSnapshot,
  selectedDynamicContentItemIndex,
  setSelectedDynamicContentItemIndex,
  setFieldValue,
  snapshots,
  state,
  useDynamicContent,
  values,
}) {
  const [loading, setLoading] = useState(LoadingState.NotLoaded);

  const { g4c_audiencetype, g4c_listtype, g4c_regardingtype } = state;

  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();
  const stages = getStages(values);

  useLookupOptions(
    dispatch,
    globalDispatch,
    loading,
    getLookups(g4c_audiencetype, g4c_listtype, g4c_regardingtype),
    setLoading,
    state
  );

  return (
    <>
      {loading !== LoadingState.Loaded ? (
        <Loading />
      ) : (
        <>
          {values.editControl ? (
            <>
              {(!useDynamicContent ||
                selectedDynamicContentItemIndex === "") && (
                <div className="sidebar-header d-flex mb-3 flex-shrink-0 ">
                  <div className="me-auto">
                    <Input
                      label="Name"
                      type="text"
                      name="controlName"
                      onChange={handleChange}
                      value={values["controlName"]}
                    />
                  </div>
                  <Button
                    text="Cancel"
                    onClick={() => {
                      setSelectedDynamicContentItemIndex("");
                      cancelControlChanges(dispatch, values);
                    }}
                    style={ButtonStyle.Link}
                  />
                </div>
              )}
              {state && state.g4c_usedynamiccontent ? (
                <CommunicationDesignerDynamicContentForm
                  {...{
                    handleChange,
                    handlePicklistChange,
                    setFieldValue,
                    state,
                    values,
                  }}
                  selectedDynamicContentItemIndex={
                    selectedDynamicContentItemIndex
                  }
                  setSelectedDynamicContentItemIndex={
                    setSelectedDynamicContentItemIndex
                  }
                  stages={stages}
                />
              ) : (
                <CommunicationDesignerControlForm
                  {...{
                    handleChange,
                    handlePicklistChange,
                    setFieldValue,
                    state,
                    values,
                  }}
                  isEditingControl={true}
                  stages={stages}
                />
              )}

              {(!useDynamicContent ||
                selectedDynamicContentItemIndex === "") && (
                <div className="d-flex justify-content-end flex-shrink-0 pt-3">
                  <Button
                    text="Apply and close"
                    onClick={() => {
                      saveUndoSnapshot(snapshots.currentState);
                      applyControlChanges(dispatch, values);
                    }}
                    style={ButtonStyle.Primary}
                  />
                </div>
              )}
            </>
          ) : (
            <CommunicationDesignerControlForm
              {...{
                handleChange,
                handlePicklistChange,
                saveUndoSnapshot,
                setFieldValue,
                state,
                values,
              }}
              isEditingControl={false}
              selectedDynamicContentItemIndex={
                selectedDynamicContentItemIndex
              }
              stages={stages}
            />
          )}
        </>
      )}
    </>
  );
}

export default CommunicationDesignerSidebar;
