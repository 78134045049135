import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Loading, Modal, Pagination } from "./_Elements";
import { ButtonStyle } from "../../js/enums";
import { getTableColumnValue, searchRecords } from "../form/Helpers";
import { useGlobalReducer } from "../../GlobalContext";
import classNames from "classnames";
import RecordsTable from "./RecordsTable";
import { CheckIcon, MinusIcon } from "../Icons";
import { getSearchTableFilters } from "./AddRemoveHelper";

function AddNewRecordModal({
  addNewRecordsToParentEntity,
  addRecordsEntitySearchName,
  addRecordsEntitySearchFilter,
  addRecordsSearchFilterArray,
  addRecordsTableColumns,
  parentId,
  recordsTableColumns,
  recordsToAdd,
  setDisplayAddModal,
  state,
}) {
  const [filters, setFilters] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [entitySearchResult, setEntitySearchResult] = useState([]);
  const [filteredEntitySearchResult, setfilteredEntitySearchResult] =
    useState([]);

  const [newRecordsSelected, setNewRecordsSelected] = useState([]);
  const [newRecordsToAdd, setNewRecordsToAdd] = useState([]);

  const [loading, setLoading] = useState(false);

  const globalDispatch = useGlobalReducer();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([]);

  const recordsPerPage = 5;

  const handleSubmitFilter = () => {
    let filterObject = {};
    let filterArray = [];
    setNewRecordsSelected([]);
    setIsSubmitting(true);
    addRecordsSearchFilterArray.forEach((filter) => {
      let key = filters.filter((f) => f.Key === filter.name);
      let value = "";
      if (key.length === 1) {
        value = key[0].Value;
      }
      filterArray.push({
        Key: filter.name,
        Value: value,
      });
      if (filter.type === "select") {
        filterObject[filter.name] = value.toString().toLowerCase();
      } else {
        filterObject[filter.name] = value;
      }
    });

    filterArray.push({
      Key: "parentId",
      Value: parentId,
    });
    filterObject["parentId"] = parentId;
    filterObject["searchfilter"] = addRecordsEntitySearchFilter;
    setFilters(filterArray);
    searchRecords(
      addRecordsEntitySearchName,
      filterObject,
      globalDispatch,
      setEntitySearchResult,
      setEntitySearchResult,
      setLoading,
      setIsSubmitting
    );
  };

  //Filter the entitySearchResult to only have those not in the recordsToAdd or newRecordsToAdd list
  useEffect(() => {
    let filteredSearchResult = [];

    if (recordsToAdd.length > 0 || newRecordsToAdd.length > 0) {
      entitySearchResult.forEach((m) => {
        if (
          !recordsToAdd.some((l) => l.Id === m.Id) &&
          !newRecordsToAdd.some((l) => l.Id === m.Id)
        ) {
          filteredSearchResult = [...filteredSearchResult, m];
        }
      });
    } else {
      filteredSearchResult = entitySearchResult;
    }
    setfilteredEntitySearchResult(filteredSearchResult);
  }, [entitySearchResult, newRecordsToAdd, recordsToAdd]);

  const toggleCheck = (record) => {
    if (newRecordsSelected.some((m) => m.Id === record.Id)) {
      //Uncheck box
      setNewRecordsSelected(
        newRecordsSelected.filter((m) => m.Id !== record.Id)
      );
    } else {
      //Check box
      setNewRecordsSelected([...newRecordsSelected, record]);
    }
  };

  const toggleCheckAllRecords = () => {
    if (
      filteredEntitySearchResult.length === newRecordsSelected.length
    ) {
      //Uncheck all records across all pages
      setNewRecordsSelected([]);
    } else {
      //Check all records across all pages
      setNewRecordsSelected(filteredEntitySearchResult);
    }
  };

  const toggleCheckAllOnPage = () => {
    if (currentRecords.every((c) => newRecordsSelected.includes(c))) {
      //Uncheck all on the current page
      setNewRecordsSelected(
        newRecordsSelected.filter((n) => !currentRecords.includes(n))
      );
    } else {
      //Check all on the current page. Make sure we don't add duplicates to the newRecordsSelected
      let updatedRecordsSelect = newRecordsSelected;
      currentRecords.forEach((c) => {
        if (!newRecordsSelected.includes(c)) {
          updatedRecordsSelect = [...updatedRecordsSelect, c];
        }
      });
      setNewRecordsSelected(updatedRecordsSelect);
    }
  };

  const removeNewRecordFromAddList = (record) => {
    let updatedList = newRecordsToAdd.filter(
      (m) => m.Id !== record.Id
    );
    setNewRecordsToAdd(updatedList);
  };

  return (
    <Modal
      title={"Add new record"}
      modalCloseButtonClick={() => {
        setDisplayAddModal(false);
      }}
      className="modal modal-dialog-scrollable modal-fullscreen"
    >
      <div className="modal-body">
        {loading && <Loading />}
        {!loading && (
          <>
            {getSearchTableFilters(
              filters,
              handleSubmitFilter,
              isSubmitting,
              addRecordsSearchFilterArray,
              setFilters,
              state
            )}
            {filteredEntitySearchResult &&
            filteredEntitySearchResult.length > 0 ? (
              <>
                <div className="d-flex flex-row-reverse mb-3 me-2">
                  <Button
                    type={"button"}
                    style={ButtonStyle.Primary}
                    text={
                      filteredEntitySearchResult.length ===
                      newRecordsSelected.length
                        ? "Unselect All Records"
                        : "Select All Records"
                    }
                    onClick={toggleCheckAllRecords}
                  />
                </div>
                <div className="list-view">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr className="table-info">
                          {addRecordsTableColumns.map((r, i) => (
                            <th
                              key={i}
                              scope="col"
                              className={classNames(r.className)}
                            >
                              {r.headingName}
                            </th>
                          ))}
                          <th
                            scope="col"
                            className={classNames(
                              "col-records-check",
                              "text-center"
                            )}
                          >
                            <FontAwesomeIcon
                              className="cursor-pointer"
                              icon={CheckIcon}
                              onClick={toggleCheckAllOnPage}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords.map((record, i) => {
                          return (
                            <tr key={i}>
                              {addRecordsTableColumns.map((c, i) =>
                                i === 0 ? (
                                  <th key={i} scope="row">
                                    {getTableColumnValue(c, record)}
                                  </th>
                                ) : (
                                  <td key={i}>
                                    {getTableColumnValue(c, record)}
                                  </td>
                                )
                              )}
                              <td
                                className="text-center"
                                onClick={() => toggleCheck(record)}
                              >
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  name={"record" + i}
                                  checked={newRecordsSelected.some(
                                    (m) => m.Id === record.Id
                                  )}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-end py-3">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    records={filteredEntitySearchResult}
                    recordsPerPage={recordsPerPage}
                    setRecords={setCurrentRecords}
                  />
                </div>
                <div className="col-md-12 d-grid d-md-flex justify-content-md-end mb-3">
                  <Button
                    disabled={newRecordsSelected.length === 0}
                    type={"button"}
                    style={ButtonStyle.Primary}
                    text={"Select"}
                    onClick={() => {
                      setNewRecordsToAdd(
                        newRecordsToAdd.concat(newRecordsSelected)
                      );
                      setNewRecordsSelected([]);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="col-12">
                <p className="text-center">
                  {"No records found with given filters."}
                </p>
              </div>
            )}
          </>
        )}
        {newRecordsToAdd.length > 0 && (
          <>
            <div className="col-12">
              <h5>Records to Add</h5>
            </div>
            <RecordsTable
              allowEdit={true}
              records={newRecordsToAdd}
              recordsPerPage={5}
              recordsTableColumns={recordsTableColumns}
              removeColumnIcon={MinusIcon}
              removeRecord={removeNewRecordFromAddList}
            />
          </>
        )}
      </div>
      <div className="modal-footer">
        <Button
          text={"Add"}
          style={ButtonStyle.Primary}
          onClick={() => addNewRecordsToParentEntity(newRecordsToAdd)}
          disabled={newRecordsToAdd.length === 0 || isSubmitting}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => setDisplayAddModal(false)}
          disabled={isSubmitting}
        />
      </div>
    </Modal>
  );
}

export default AddNewRecordModal;
