import classNames from "classnames";
import { DesignMode } from "./enums";
import { DroppableElement } from "../../elements/_Elements";
import EditCommunicationControl from "./EditCommunicationControl";
import CommunicationControl from "./CommunicationControl";
import { Fragment } from "react";
import { checkIfDynamicContentItemSelected } from "./Helpers";

function CommunicationTableRow({
  activeId,
  allowEdit,
  row,
  rowIndex,
  saveCurrentState,
  saveUndoSnapshot,
  selectedDynamicContentItemIndex,
  setFieldValue,
  useDynamicContent,
  values,
}) {
  const allowEditControls =
    allowEdit && values.designMode === String(DesignMode.Controls);

  const columnClass =
    row.Columns.length === 3
      ? "four"
      : row.Columns.length === 2
      ? "six"
      : "twelve";

  return (
    <table key={rowIndex} className="content-table twelve columns">
      <tbody>
        <tr>
          {row.Columns.map((column, columnIndex) => {
            return (
              <td
                key={columnIndex}
                className={classNames(
                  "content-table column",
                  columnClass
                )}
              >
                {allowEditControls && (
                  <>
                    <DroppableElement
                      id={`control-${rowIndex}-${columnIndex}-0`}
                    />
                    {column.Controls.length === 0 && (
                      <div className="empty-content-zone">
                        Empty content zone
                      </div>
                    )}
                  </>
                )}
                {column.Controls.map((control, controlIndex) => {
                  const {
                    Attribute,
                    AttributeName,
                    AttributeTarget,
                    AttributeType,
                    DynamicContentItems,
                    Entity,
                    HideDefaultContent,
                    Name,
                    Properties,
                    Template,
                    UUID,
                  } = control;
                  const droppableId = `control-${rowIndex}-${columnIndex}-${
                    controlIndex + 1
                  }`;

                  //These are the properties of the default content of the control
                  //with regards to dynamic content
                  let selectedContentProperties = Properties.reduce(
                    (result, prop) => {
                      result[prop.Name] = prop.Value;
                      return result;
                    },
                    {}
                  );

                  //This if statement is here to replace the above properties with the properties
                  //assigned to the selected dynamic content item. That way the changes made in the sidebar
                  //can be reflected onto the communication table
                  if (
                    useDynamicContent &&
                    checkIfDynamicContentItemSelected(
                      selectedDynamicContentItemIndex
                    ) &&
                    values &&
                    values.editControl &&
                    values.editControl.controlIndex ===
                      controlIndex &&
                    values.editControl.rowIndex === rowIndex &&
                    values.editControl.columnIndex === columnIndex
                  ) {
                    selectedContentProperties =
                      values.dynamicContentItemsValuesArray[
                        selectedDynamicContentItemIndex
                      ];
                  }
                  //Else set the HideDefaultContent property on the default as
                  //well as the remaining attribute values
                  else {
                    selectedContentProperties.HideDefaultContent =
                      useDynamicContent ? HideDefaultContent : false;
                    selectedContentProperties.Attribute = Attribute;
                    selectedContentProperties.AttributeName =
                      AttributeName;
                    selectedContentProperties.AttributeTarget =
                      AttributeTarget;
                    selectedContentProperties.AttributeType =
                      AttributeType;
                    selectedContentProperties.DynamicContentItems =
                      DynamicContentItems;
                    selectedContentProperties.Entity = Entity;
                    selectedContentProperties.UUID = UUID;
                  }

                  return allowEditControls ? (
                    <Fragment key={controlIndex}>
                      <EditCommunicationControl
                        name={Name}
                        template={Template}
                        {...{
                          activeId,
                          columnIndex,
                          controlIndex,
                          rowIndex,
                          saveCurrentState,
                          saveUndoSnapshot,
                          selectedDynamicContentItemIndex,
                          setFieldValue,
                          useDynamicContent,
                          values,
                        }}
                        properties={selectedContentProperties}
                      />
                      <DroppableElement id={droppableId} />
                    </Fragment>
                  ) : !useDynamicContent || !HideDefaultContent ? (
                    <CommunicationControl
                      key={controlIndex}
                      name={Name}
                      properties={selectedContentProperties}
                      template={Template}
                      useDynamicContent={useDynamicContent}
                    />
                  ) : (
                    <></>
                  );
                })}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}

export default CommunicationTableRow;
