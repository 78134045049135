import lookups from "./lookups";
import DirectDebitProcessing from "../components/elements/DirectDebitProcessing";
import { ButtonStyle, DirectDebitOutcome } from "../js/enums";
import Button from "../components/elements/Button";
import { downloadAnnotation } from "../js/utility";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const PaymentBatchFormStages = [
  {
    title: "Payment Batch",
    id: "payment-batch",
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "name",
            className: "col-md-12",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "ddProcess",
            className: "col-md-12",
            type: "component",
            component: DirectDebitProcessing,
            displayIfFromState: (state) =>
              state.g4b_reference && state.g4b_reference !== "",
          },
        ],
      },
    ],
  },
  {
    title: "Process Information",
    id: "process-information",
    editOnOverview: false,
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_reference",
            className: "col-md-6",
            label: "Reference",
            type: "nvarchar",
            getReadOnlyValue: (state) => {
              return state["g4b_reference"];
            },
          },
          {
            name: "g4b_batchdate",
            className: "col-md-6",
            description: "",
            label: "Batch Date",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Items",
    id: "payment-batch-items",
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    displayEditButtonFromState: () => false,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          submitSearchOnLoad: true,
          useSubmitButton: true,
          fields: [
            {
              name: "firstname",
              className: "col-name",
              label: "First Name",
            },
            {
              name: "lastname",
              className: "col-name",
              label: "Last Name",
            },
            {
              name: "g4m_membershipaccountnumber",
              className: "col-name",
              label: "Membership Account Number",
              linkedEntityId: "g4m_membershipaccountid",
              linkForNewRecord: "/membership-account",
            },
            {
              name: "g4b_amount",
              className: "col-number",
              type: "money",
              label: "Amount",
            },
            {
              name: "g4b_processed",
              className: "col-bool",
              type: "boolean",
              label: "Processed",
            },
            {
              name: "g4b_dateprocessed",
              className: "col-date",
              type: "datetime",
              label: "Date Processed",
              dateFormat: "dd/MM/yyyy",
            },
            {
              name: "g4b_paymentsuccess",
              className: "col-bool",
              type: "boolean",
              label: "Payment Success",
            },
            {
              name: "g4b_collectionreference",
              className: "col-name",
              type: "nvarchar",
              label: "Collection Reference",
            },
            {
              name: "g4b_bookingpaymentidname",
              className: "col-name",
              label: "Booking Payment",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "firstname",
              description: "",
              label: "First Name",
            },
            {
              name: "lastname",
              description: "",
              label: "Last Name",
            },
            {
              name: "itemprocessed",
              description: "",
              key: "processed",
              label: "Processed only",
              type: "bit",
            },
            {
              name: "paymentsuccess",
              description: "",
              label: "Payment successful only",
              type: "bit",
            },
          ],
          initialOrderedBy: "firstname",
          initialOrderedByAsc: true,
          lookup: lookups.g4b_paymentbatchitem.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Responses",
    id: "payment-batch-item-responses",
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    displayEditButtonFromState: () => false,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          submitSearchOnLoad: true,
          useSubmitButton: true,
          fields: [
            {
              name: "g4b_externaltxid",
              className: "col-name",
              label: "External Tansaction Id",
            },
            {
              name: "g4b_externalaccountname",
              className: "col-name",
              label: "Account Name",
            },
            {
              name: "g4b_externalpaymentamount",
              className: "col-number",
              type: "money",
              label: "Payment Amount",
            },
            {
              name: "g4b_externalpaymentdate",
              className: "col-date",
              type: "datetime",
              label: "Payment Date",
              dateFormat: "dd/MM/yyyy",
            },
            {
              name: "g4b_outcome",
              className: "col-name",
              label: "Outcome",
            },
            {
              name: "g4b_processed",
              className: "col-bool",
              type: "boolean",
              label: "Processed",
            },
            {
              name: "g4b_bookingpaymentidname",
              className: "col-name",
              label: "Booking Payment",
            },
            {
              name: "g4b_paymentbatchitemidname",
              className: "col-name",
              label: "Payment Batch Item",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "outcome",
              description: "",
              isMulti: false,
              label: "Outcome",
              type: "select",
              enum: DirectDebitOutcome,
            },
            {
              name: "processed",
              description: "",
              label: "Processed only",
              type: "bit",
            },
          ],
          initialOrderedBy: "g4b_externaltxid",
          initialOrderedByAsc: true,
          lookup: lookups.g4b_paymentbatchitemresponse.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Logs",
    id: "logs",
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "mimetype",
              label: "Type",
              className: "col-name",
            },
            {
              name: "filesize",
              displayFieldValue: (value) =>
                `${value.toLocaleString("en-GB", {
                  useGrouping: "true",
                  maximumFractionDigits: "0",
                })}`,
              label: "File Size",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "download",
              displayFieldValue: (_, record) => (
                <Button
                  onClick={() => downloadAnnotation(record.Id)}
                  style={ButtonStyle.Secondary}
                  text="Download"
                />
              ),
              label: "Download",
              className: "col-short-name",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "mimetype",
              label: "Type",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "annotation",
          useSubForm: false,
        },
      },
    ],
  },
];
