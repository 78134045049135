import { BackgroundStyle } from "../../../js/enums";
import { BadgeList } from "../../elements/_Elements";

function DonationSummary({ state }) {
  const { g4d_hasactivedeclaration } = state;

  const badgeItems = [
    {
      backgroundStyle: g4d_hasactivedeclaration
        ? BackgroundStyle.Success
        : BackgroundStyle.Info,
      text: g4d_hasactivedeclaration
        ? "Has active declaration"
        : "Does not active declaration",
    },
  ];

  return <BadgeList className="mb-3" items={badgeItems} />;
}

export default DonationSummary;
