import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { DonationBatchHeaderStatus } from "../js/enums";
import { DonationBatchHeaderFormStages } from "./donationBatchHeader";

export const DonationBatchHeadersFormStages = [
  {
    title: "Donation Batches",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4d_batchreference",
              className: "col-short-name",
              type: "string",
            },
            {
              name: "g4d_batchrecordcount",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4d_batchstatus",
              className: "col-short-name",
              type: "statusbadge",
              enum: DonationBatchHeaderStatus,
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/donation-batch-header",
          lookup: lookups.g4d_donationbatchheader.all,
          subFormStages: DonationBatchHeaderFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
