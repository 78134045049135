import {
  Button,
  Card,
  SelectWithFiltering,
} from "../../elements/_Elements";
import {
  checkIfDynamicContentItemSelected,
  getAttributeOperatorEnum,
  getDynamicContentTitle,
} from "./Helpers";
import { ButtonStyle } from "../../../js/enums";
import CommunicationDesignerDynamicContentAttributeValue from "./CommunicationDesignerDynamicContentAttributeValue";

function CommunicationDesignerDynamicContentSettings({
  removeDynamicContentItem,
  selectedDynamicContentItemIndex,
  setEditingDynamicContentControl,
  setFieldValue,
  setSelectedDynamicContentItemIndex,
  state,
  values,
}) {
  const selectedDynamicContentItem =
    values.dynamicContentItemsValuesArray[
      selectedDynamicContentItemIndex
    ];
  const getSettingsComponent = () => {
    if (
      checkIfDynamicContentItemSelected(
        selectedDynamicContentItemIndex
      )
    ) {
      return (
        <Card className="mb-3">
          <SelectWithFiltering
            className={"mb-3"}
            label={"Attribute Operator"}
            name={"attribute-operator"}
            onChange={(event) => {
              let updatedValues =
                values.dynamicContentItemsValuesArray;
              updatedValues[
                selectedDynamicContentItemIndex
              ].AttributeOperator = event.key;
              setFieldValue(
                "dynamicContentItemsValuesArray",
                updatedValues
              );
              return true;
            }}
            isSearchable={true}
            mandatory={true}
            options={getAttributeOperatorEnum(values.attributeType)}
            value={
              selectedDynamicContentItem
                ? selectedDynamicContentItem.AttributeOperator
                : ""
            }
          />
          <CommunicationDesignerDynamicContentAttributeValue
            selectedDynamicContentItem={selectedDynamicContentItem}
            selectedDynamicContentItemIndex={
              selectedDynamicContentItemIndex
            }
            setFieldValue={setFieldValue}
            state={state}
            values={values}
          />
        </Card>
      );
    } else {
      return <></>;
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="m-0">
          {selectedDynamicContentItemIndex === "default"
            ? "Default"
            : getDynamicContentTitle(
                selectedDynamicContentItem.AttributeOperator,
                selectedDynamicContentItem.AttributeValue1,
                selectedDynamicContentItem.AttributeValue1Caption,
                selectedDynamicContentItem.AttributeValue2
              )}
        </h5>
        <Button
          type={"button"}
          style={ButtonStyle.Link}
          text={"Back"}
          onClick={() => {
            if (selectedDynamicContentItemIndex !== "default") {
              //If one of the attribute related fields
              //weren't set then be sure to remove it from the dynamic content item list
              let selectedItem =
                values.dynamicContentItemsValuesArray[
                  selectedDynamicContentItemIndex
                ];
              if (
                !selectedItem.AttributeOperator ||
                !selectedItem.AttributeValue1
              )
                removeDynamicContentItem(
                  selectedDynamicContentItemIndex
                );
            }
            setSelectedDynamicContentItemIndex("");
            setEditingDynamicContentControl(false);
          }}
        />
      </div>
      {getSettingsComponent()}
    </>
  );
}

export default CommunicationDesignerDynamicContentSettings;
