export const CommunicationAuditFormStages = [
  {
    title: "General",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_auditdate",
            description: "",
            label: "Date",
            type: "datetime",
          },
          {
            name: "g4c_page",
            description: "",
            label: "Page",
            type: "nvarchar",
          },          
          {
            name: "g4c_contactidname",
            description: "",
            label: "Contact",
            linkedEntityId: "g4c_contactid",
            linkForNewRecord: "/contact",
            type: "nvarchar",
          },
          {
            name: "g4c_additionalinformation",
            description: "",
            label: "Additional information",
            type: "nvarchar",
            className:"col-md-12"
          },
        ],
      },
    ],
  },
  {
    title: "Communication",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_communicationidname",
            description: "",
            label: "Communication",
            linkedEntityId: "g4c_communicationid",
            linkForNewRecord: "/communication",
            type: "nvarchar",
          },
          {
            name: "g4c_communicationactivityname",
            description: "",
            label: "Activity",
            linkedEntityId: "g4c_communicationactivity",
            linkForNewRecord: "/communication-activity",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
