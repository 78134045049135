import React, { useState } from "react";
import { ButtonStyle } from "../../../js/enums";
import { Button, Modal } from "../../elements/_Elements";
import CommunicationAttributes from "./CommunicationAttributes";

function CommunicationAttributesModal({
  field,
  setCustomAction,
  setFieldValue,
  state,
  values,
}) {
  const targetAtEntityNumber =
    String(state.g4c_listtype) === "1" ? 6 : 3; //6 for account, 3 for contact
  const [selectedEntity, setSelectedEntity] = useState(
    targetAtEntityNumber
  );
  const [selectedAttribute, setSelectedAttribute] = useState("");

  return (
    <Modal
      title={"Add Attribute"}
      modalCloseButtonClick={() => {
        setCustomAction("");
      }}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body min-vh-50">
        <CommunicationAttributes
          bodyText={
            "Add an attribute value for the selected recipient type."
          }
          selectedAttribute={selectedAttribute}
          setSelectedAttribute={setSelectedAttribute}
          selectedEntity={selectedEntity}
          setSelectedEntity={setSelectedEntity}
          state={state}
        />
      </div>
      <div className="modal-footer">
        <Button
          text={"Confirm"}
          style={ButtonStyle.Primary}
          onClick={() => {
            setFieldValue(
              field.name,
              values[field.name] +
                ` <<${selectedAttribute.toLowerCase()}>>`
            );
            setCustomAction("");
          }}
          disabled={!selectedAttribute}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => {
            setCustomAction("");
          }}
        />
      </div>
    </Modal>
  );
}

export default CommunicationAttributesModal;
