const ABTestMetric = Object.freeze({
  "Unique Clicks": 0,
  "Unique Opens": 1,
});

const ABTestPublishedStatus = Object.freeze({
  Unpublished: 1,
  Published: 4,
});

const ABTestWinnerType = Object.freeze({
  Automatic: 0,
  Manual: 1,
});

const AlertStyle = Object.freeze({
  Primary: "alert-primary",
  Secondary: "alert-secondary",
  Success: "alert-success",
  Danger: "alert-danger",
  Warning: "alert-warning",
  Info: "alert-info",
  Light: "alert-light",
  Dark: "alert-dark",
});

const AudienceType = Object.freeze({
  "A/B Testing": 32,
  Existing: 4,
  Flow: 16,
});

const AudienceTypeNoAB = Object.freeze({
  Existing: 4,
  Flow: 16,
});

const BackgroundStyle = Object.freeze({
  Primary: "bg-primary",
  Secondary: "bg-secondary",
  Success: "bg-success",
  Danger: "bg-danger",
  Warning: "bg-warning",
  Info: "bg-info",
  Light: "bg-light",
  Dark: "bg-dark",
});

const BeneficiaryRequirement = Object.freeze({
  "Not Required": 1,
  Requested: 2,
  Required: 3,
});

const BeneficiaryRequirementMembership = Object.freeze({
  Required: 3,
});

const BookingStatus = Object.freeze({
  Active: 1,
  Pending: 2,
  Inactive: 3,
  Incomplete: 4,
  Reserved: 5,
});

const ButtonStyle = Object.freeze({
  Primary: "btn-primary",
  Secondary: "btn-secondary",
  Success: "btn-success",
  Danger: "btn-danger",
  Warning: "btn-warning",
  Info: "btn-info",
  Light: "btn-light",
  Dark: "btn-dark",
  Link: "btn-link",
  OutlinePrimary: "btn-outline-primary",
  OutlineSecondary: "btn-outline-secondary",
  Close: "btn-close",
});

const CardProvider = Object.freeze({
  FirstDataSOAP: "FirstDataSOAP",
  Optomany: "Optomany",
  OptomanyPBL: "OptomanyPBL",
  Test: "CommIdea",
  WorldPay: "WorldPay",
});

const CalendarItemType = Object.freeze({
  Include: 1,
  Exclude: 2,
  Override: 3,
});

const ChipPinProvider = Object.freeze({
  Clover: "Clover",
  OptomanyPED: "OptomanyPED",
});

const CloverSignatureLocation = Object.freeze({
  "On Screen": "OnScreen",
  "On Paper": "OnPaper",
  None: "None",
});

const CloverTipMode = Object.freeze({
  Provided: "Provided",
  "On Screen": "OnScreen",
  None: "None",
});

const CommunicationActivityStatus = Object.freeze({
  Open: 0,
  Completed: 1,
  Cancelled: 2,
  Scheduled: 3,
});

const CommunicationAudienceType = Object.freeze({
  Marketing: 492740000,
  Transactional: 492740001,
});

const CommunicationPublishedStatus = Object.freeze({
  Unpublished: 1,
  Inactive: 2,
  Testing: 3,
  Published: 4,
});

const CouponVoucherStatus = Object.freeze({
  Available: 1,
  Redeemed: 2,
  Expired: 3,
  Cancelled: 4,
});

const DateTimePickerRangesAll = Object.freeze({
  Custom: 1,
  On: 2,
  "On or After": 3,
  "On or Before": 4,
  Yesterday: 5,
  Today: 6,
  Tomorrow: 7,
  "Next 7 Days": 8,
  "Last 7 Days": 9,
  "Next Month": 10,
  "Last Month": 11,
  "This Month": 12,
  "Next Year": 13,
  "Last Year": 14,
  "This Year": 15,
});

const DateTimePickerRangesFuture = Object.freeze({
  Custom: 1,
  On: 2,
  "On or After": 3,
  "On or Before": 4,
  Today: 6,
  Tomorrow: 7,
  "Next 7 Days": 8,
  "Next Month": 10,
  "This Month": 12,
  "Next Year": 13,
  "This Year": 15,
});

const DateTimePickerRangesPast = Object.freeze({
  Custom: 1,
  On: 2,
  "On or After": 3,
  "On or Before": 4,
  Yesterday: 5,
  Today: 6,
  "Last 7 Days": 9,
  "Last Month": 11,
  "This Month": 12,
  "Last Year": 14,
  "This Year": 15,
});

const DayOfWeek = Object.freeze({
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
});

const DeclarationCancellationReason = Object.freeze({
  "Not Cancelled": 0,
  "No Longer Pay Tax": 1,
  "Do Not Approve": 2,
  "Refused To Give Reason": 3,
  Duplicate: 4,
});

const DirectDebitOutcome = Object.freeze({
  "H - Successful Transaction": "H",
  "F - Failed Transaction": "F",
  "j - Processing": "j",
  "n - Unprocessed": "n",
});

const DefaultCommunicationMethods = Object.freeze({
  Port: 1,
  Folder: 2,
  "Web Service": 3,
});

const DefaultPrintOptions = Object.freeze({
  Receipt: 1,
  Ticket: 2,
  Wizard: 3,
});

const DelayDurationUnit = Object.freeze({
  Hours: 0,
  Days: 1,
  Weeks: 2,
  Months: 3,
});

const DeliveryFulfilmentStatus = Object.freeze({
  Received: 0,
  "In Progress": 1,
  Dispatched: 2,
  Delivered: 3,
});

const DirectDebitProcesses = Object.freeze({
  "Send Payment Requests": "Send",
  "Retrieve Payment Responses": "Retrieve",
  "Process Payment Responses": "Process",
});

const DonationStatus = Object.freeze({
  Pending: 0,
  Sent: 1,
  Approved: 2,
  Rejected: 3,
  Cancelled: 4,
  "Not Eligible": 5,
  Unprocessed: 6,
});

const DonationBatchHeaderStatus = Object.freeze({
  Pending: 0,
  Sent: 1,
  Processed: 2,
  Failed: 3,
});

const DonationBatchLineStatus = Object.freeze({
  Pending: 0,
  Sent: 1,
  Accepted: 2,
  Rejected: 3,
});

const DynamicContentAttributeOperator = Object.freeze({
  "Equal To": "=",
  "Starts With": "StartsWith",
  "Ends With": "EndsWith",
  In: "In",
  "Not In": "NotIn",
  "Less Than": "<",
  "Greater Than": ">",
  "Less Than or Equal To": "<=",
  "Greater Than or Equal To": ">=",
  Between: "Between",
});

const ExclusionDates = Object.freeze({
  None: 1,
  "Bank Holidays": 2,
  "All Exclusion Dates": 3,
});

const FlowMode = Object.freeze({
  Test: 1,
  Live: 2,
});

const Gender = Object.freeze({
  Male: 1,
  Female: 2,
});

const GlobalDispatchMethods = Object.freeze({
  AddNotification: "Add_Notification",
  RemoveNotification: "Remove_Notification",
  ResetInitialState: "Reset_Initial_State",
  SetInitialState: "Set_Initial_State",
  SetSitemap: "Set_Sitemap",
});

const GridBreakpoints = Object.freeze({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});

const GroupType = Object.freeze({ Page: 1, Product: 21, Widget: 22 });

const InputType = Object.freeze({
  Button: "button",
  Checkbox: "checkbox",
  Radio: "radio",
  Submit: "submit",
  Text: "text",
});

const LoadingState = Object.freeze({
  NotLoaded: "NotLoaded",
  Loading: "Loading",
  Loaded: "Loaded",
});

const MandateStatus = Object.freeze({
  Active: 1,
  Pending: 2,
  Inactive: 3,
});

const MembershipInitialPeriod = Object.freeze({
  Month: 3,
  Year: 4,
});

const MembershipLife = Object.freeze({
  FixedEndDate: 1,
  Month: 3,
  Year: 4,
  Indefinite: 5,
});

const MembershipPeriod = Object.freeze({
  FixedEndDate: 1,
  Month: 3,
  Year: 4,
});

const Months = Object.freeze({
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
});

const NoPurchaserReason = Object.freeze({
  Child: 1,
  Refused: 2,
  "Too Busy": 3,
  Offline: 4,
  "Anonymous Products": 5,
  "Third Party": 6,
});

const OverviewColumn = Object.freeze({
  Main: 1,
  Side: 2,
});

const PassUsageType = Object.freeze({
  Day: 1,
  Entry: 2,
});

const PaymentDeviceEngagement = Object.freeze({
  "Card Holder Present": 1,
  "Telephone Order": 2,
  "Mail Order": 3,
});

const PaymentOption = Object.freeze({
  ProRata: 0,
  FixedFee: 1,
});

const PaymentStatus = Object.freeze({
  Submitted: 1,
  Approved: 2,
  Cancelled: 3,
  Rejected: 4,
  Scheduled: 5,
  "In Progress": 6,
});

const PaymentType = Object.freeze({
  Cash: 1,
  Card: 2,
  "Chip & Pin": 3,
  Invoice: 4,
  Discount: 5,
  Credit: 7,
  Unrefunded: 10,
  Voucher: 14,
  Coupon: 17,
  Finance: 21,
  Commission: 23,
  Rounding: 24,
  Promotion: 25,
});

const PreferredContactMethod = Object.freeze({
  Any: 1,
  Email: 2,
  Phone: 3,
  Mail: 5,
});

const PriceListMode = Object.freeze({
  Default: 0,
});

const PriceType = Object.freeze({ PeakPrice: 1, OffPeakPrice: 2 });

const PrinterJobType = Object.freeze({
  "Account Address": "AccountAddress",
  Card: "Card",
  "Contact Address": "ContactAddress",
  Coupon: "Coupon",
  Membership: "MembershipType",
  Receipt: "Receipt",
  Ticket: "Ticket",
  "Till Report": "TillReport",
  Voucher: "Voucher",
});

const ProductGroupType = Object.freeze({
  "Promotion Inclusion": 1,
  "Promotion Exclusion": 2,
});

const ProductType = Object.freeze({
  AutoScheduled: 3,
  Bundle: 17,
  Coupon: 7,
  "Delivery Option": 10,
  Donation: 15,
  Fixture: 4,
  Membership: 20,
  Pass: 16,
  Scheduled: 2,
  Series: 5,
  Stock: 1,
  Voucher: 8,
});

const ProductTypeNoFixturesAndSeries = Object.freeze({
  AutoScheduled: 3,
  Bundle: 17,
  Coupon: 7,
  "Delivery Option": 10,
  Donation: 15,
  Membership: 20,
  Pass: 16,
  Scheduled: 2,
  Stock: 1,
  Voucher: 8,
});

const ProductModifierType = Object.freeze({
  Text: 0,
  "Yes/No": 1,
  Options: 2,
});

// Provider values are case sensitive
const Providers = Object.freeze({
  "Ticket Validation": "Green4TicketValidation",
  Skidata: "Skidata",
  "Skidata V2": "SkidataV2",
});

const RecordStatus = Object.freeze({
  Active: 0,
  Inactive: 1,
});

const Regarding = Object.freeze({
  Booking: 1, //"g4b_booking"
  "Booking Payment": 2, //"g4b_bookingpayment"
  Contact: 3, //"contact"
  "Membership (Member)": 4, //"g4m_membership"
  "Membership (Owner)": 5, //"g4m_membership"
  Coupon: 7,
  Voucher: 8,
});

const RegardingContactOnly = Object.freeze({
  Contact: 3,
});

const RegardingForAccount = Object.freeze({
  Booking: 1, //"g4b_booking"
  Contact: 3, //"contact"
});

const ReportType = Object.freeze({
  Operational: 1,
  Financial: 2,
  Contacts: 3,
  EmailMarketing: 4,
  Memberships: 5,
  Support: 6,
  SalesAndBookings: 7,
});

const SalesPlanType = Object.freeze({
  Fixture: 1,
  Series: 2,
  Other: 3,
});

const SeatNumberDirection = Object.freeze({
  "Left to Right": 1,
  "Right to Left": 2,
  "Zig Zag": 3,
});

const SelectedStartType = Object.freeze({
  Immediately: 0,
  Scheduled: 1,
});

const SkipBeneficiaryLevel = Object.freeze({
  None: 0,
  All: 1,
  Requested: 2,
  "Requested By Product": 3,
});

const StadiumOptions = Object.freeze({
  Buttons: 1,
  Tabs: 2,
});

const StadiumOptionsDefaultTab = Object.freeze({
  Fixtures: 1,
  Series: 2,
  Other: 3,
});

const TargetedAtTypeNames = Object.freeze({
  Account: "account",
  Contact: "contact",
});

const TargetedAtType = Object.freeze({
  Account: 1,
  Contact: 2,
});

const TelegramQueueAction = Object.freeze({
  New: 1,
  Update: 2,
});

const TelegramQueueStage = Object.freeze({
  Unprocessed: 1,
  Processing: 2,
  Processed: 3,
  Void: 4,
  "Processing Failed": 5,
});

const ThreeDSChallengeIndicator = Object.freeze({
  "No Preference": 1,
  "No Challenge Requested": 2,
  "Challenge Requested": 3,
});

const TicketReprintBehaviour = Object.freeze({
  Default: 1,
  "Print Wizard": 2,
});

const TimeInterval = Object.freeze({
  "1 minute": 1,
  "15 minutes": 15,
  "30 minutes": 30,
  "45 minutes": 45,
  "1 hour": 60,
  "1.5 hours": 90,
  "2 hours": 120,
  "2.5 hours": 150,
  "3 hours": 180,
  "3.5 hours": 210,
  "4 hours": 240,
  "4.5 hours": 270,
  "5 hours": 300,
  "5.5 hours": 330,
  "6 hours": 360,
  "6.5 hours": 390,
  "7 hours": 420,
  "7.5 hours": 450,
  "8 hours": 480,
});

const VoucherProvider = Object.freeze({
  TescoClubcard: "TescoClubcard",
});

const YesNo = Object.freeze({
  No: 0,
  Yes: 1,
});

const ZigZagStart = Object.freeze({
  "Bottom Left": 1,
  "Bottom Right": 2,
  "Top Left": 3,
  "Top Right": 4,
});

export {
  ABTestMetric,
  ABTestPublishedStatus,
  ABTestWinnerType,
  AlertStyle,
  AudienceType,
  AudienceTypeNoAB,
  BackgroundStyle,
  BeneficiaryRequirement,
  BeneficiaryRequirementMembership,
  BookingStatus,
  ButtonStyle,
  CalendarItemType,
  CardProvider,
  ChipPinProvider,
  CloverSignatureLocation,
  CloverTipMode,
  CommunicationActivityStatus,
  CommunicationAudienceType,
  CommunicationPublishedStatus,
  CouponVoucherStatus,
  DateTimePickerRangesAll,
  DateTimePickerRangesFuture,
  DateTimePickerRangesPast,
  DayOfWeek,
  DeclarationCancellationReason,
  DirectDebitOutcome,
  DefaultCommunicationMethods,
  DefaultPrintOptions,
  DelayDurationUnit,
  DeliveryFulfilmentStatus,
  DirectDebitProcesses,
  DonationStatus,
  DonationBatchHeaderStatus,
  DonationBatchLineStatus,
  DynamicContentAttributeOperator,
  ExclusionDates,
  FlowMode,
  Gender,
  GlobalDispatchMethods,
  GridBreakpoints,
  GroupType,
  InputType,
  LoadingState,
  MandateStatus,
  MembershipInitialPeriod,
  MembershipLife,
  MembershipPeriod,
  Months,
  NoPurchaserReason,
  OverviewColumn,
  PassUsageType,
  PaymentDeviceEngagement,
  PaymentOption,
  PaymentStatus,
  PaymentType,
  PreferredContactMethod,
  PriceListMode,
  PriceType,
  PrinterJobType,
  ProductGroupType,
  ProductType,
  ProductTypeNoFixturesAndSeries,
  ProductModifierType,
  Providers,
  RecordStatus,
  Regarding,
  RegardingContactOnly,
  RegardingForAccount,
  ReportType,
  SeatNumberDirection,
  SalesPlanType,
  SelectedStartType,
  SkipBeneficiaryLevel,
  StadiumOptions,
  StadiumOptionsDefaultTab,
  TargetedAtType,
  TargetedAtTypeNames,
  TelegramQueueAction,
  TelegramQueueStage,
  ThreeDSChallengeIndicator,
  TicketReprintBehaviour,
  TimeInterval,
  VoucherProvider,
  YesNo,
  ZigZagStart,
};
