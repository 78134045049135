import {
  BookingControl,
  ButtonControl,
  CouponControl,
  DividerControl,
  ImageControl,
  ImageTextControl,
  MembershipRenewalControl,
  SocialFollowControl,
  TextControl,
  VoucherControl,
} from "./controls";
import { mapControlProperties } from "./Helpers";

function CommunicationControl({
  name,
  properties,
  template,
  useDynamicContent,
}) {
  const controlProperties = mapControlProperties(
    properties,
    template
  );

  const applyDynamicContent =
    useDynamicContent &&
    properties.DynamicContentItems &&
    properties.DynamicContentItems.length > 0;

  if (!applyDynamicContent) {
    return (
      <div control-name={name}>
        {template === "booking-control" ? (
          <BookingControl properties={controlProperties} />
        ) : template === "button-control" ? (
          <ButtonControl properties={controlProperties} />
        ) : template === "coupon-control" ? (
          <CouponControl properties={controlProperties} />
        ) : template === "divider-control" ? (
          <DividerControl properties={controlProperties} />
        ) : template === "image-control" ? (
          <ImageControl properties={controlProperties} />
        ) : template === "image-text-control" ? (
          <ImageTextControl properties={controlProperties} />
        ) : template === "membership-renewal-control" ? (
          <MembershipRenewalControl properties={controlProperties} />
        ) : template === "social-follow-control" ? (
          <SocialFollowControl properties={controlProperties} />
        ) : template === "text-control" ? (
          <TextControl properties={controlProperties} />
        ) : template === "voucher-control" ? (
          <VoucherControl properties={controlProperties} />
        ) : (
          <p>Undefined control</p>
        )}
      </div>
    );
  }
  return (
    <div control-name={name}>
      <div
        className="dynamic-content-container"
        data-id={properties.UUID}
        data-attribute={properties.Attribute}
        data-hidedefaultcontent={properties.HideDefaultContent}
        data-attributetype={properties.AttributeType}
      >
        <div
          className="default-content-item"
          data-id={properties.UUID}
        >
          {properties.HideDefaultContent ? (
            <p className="fst-italic fw-lighter">
              This is a dynamic content item with the default content
              hidden
            </p>
          ) : template === "booking-control" ? (
            <BookingControl properties={controlProperties} />
          ) : template === "button-control" ? (
            <ButtonControl properties={controlProperties} />
          ) : template === "coupon-control" ? (
            <CouponControl properties={controlProperties} />
          ) : template === "divider-control" ? (
            <DividerControl properties={controlProperties} />
          ) : template === "image-control" ? (
            <ImageControl properties={controlProperties} />
          ) : template === "image-text-control" ? (
            <ImageTextControl properties={controlProperties} />
          ) : template === "membership-renewal-control" ? (
            <MembershipRenewalControl
              properties={controlProperties}
            />
          ) : template === "social-follow-control" ? (
            <SocialFollowControl properties={controlProperties} />
          ) : template === "text-control" ? (
            <TextControl properties={controlProperties} />
          ) : template === "voucher-control" ? (
            <VoucherControl properties={controlProperties} />
          ) : (
            <p>Undefined control</p>
          )}
        </div>
        <div className="dynamic-content-items">
          {properties &&
            properties.DynamicContentItems &&
            properties.DynamicContentItems.map((d) => {
              let dynamicProperties = d.Properties.reduce(
                (result, prop) => {
                  result[prop.Name] = prop.Value;
                  return result;
                },
                {}
              );
              const dynamicControlProperties = mapControlProperties(
                dynamicProperties,
                template
              );

              return (
                <div
                  className="dynamic-content-item"
                  data-id={d.UUID}
                  data-operator={d.AttributeOperator}
                  data-value1={d.AttributeValue1}
                  data-value2={d.AttributeValue2}
                  style={{ display: "none" }}
                >
                  {template === "booking-control" ? (
                    <BookingControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "button-control" ? (
                    <ButtonControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "coupon-control" ? (
                    <CouponControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "divider-control" ? (
                    <DividerControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "image-control" ? (
                    <ImageControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "image-text-control" ? (
                    <ImageTextControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "membership-renewal-control" ? (
                    <MembershipRenewalControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "social-follow-control" ? (
                    <SocialFollowControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "text-control" ? (
                    <TextControl
                      properties={dynamicControlProperties}
                    />
                  ) : template === "voucher-control" ? (
                    <VoucherControl
                      properties={dynamicControlProperties}
                    />
                  ) : (
                    <p>Undefined control</p>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default CommunicationControl;
