import { useEffect, useState } from "react";
import CommunicationAttributes from "./CommunicationAttributes";
import {
  getEntityNameForRegarding,
  getEntityNumberForRegarding,
} from "../../../js/utility";
import ReadOnlyLabel from "../../elements/ReadOnlyLabel";
import CommunicationDesignerDynamicContentList from "./CommunicationDesignerDynamicContentList";
import { Card } from "../../elements/_Elements";
import { convertTypeNumberToEntityDisplayName } from "./Helpers";

function CommunicationDesignerDynamicContentForm({
  handleChange,
  handlePicklistChange,
  selectedDynamicContentItemIndex,
  setSelectedDynamicContentItemIndex,
  setFieldValue,
  stages,
  state,
  values,
}) {
  const [initialLoad, setInitialLoad] = useState(true);
  const targetAtEntityNumber =
    String(state.g4c_listtype) === "1" ? 6 : 3; //6 for account, 3 for contact
  const [selectedEntity, setSelectedEntity] = useState(
    targetAtEntityNumber
  );

  useEffect(() => {
    if (initialLoad) {
      let initialEntityValue =
        values && values.entity
          ? getEntityNumberForRegarding(values.entity)
          : targetAtEntityNumber;
      setSelectedEntity(initialEntityValue);
      setFieldValue(
        "entity",
        getEntityNameForRegarding(initialEntityValue)
      );
      setInitialLoad(false);
    }
  }, [initialLoad, setFieldValue, targetAtEntityNumber, values]);

  const [selectedAttribute, setSelectedAttribute] = useState(
    values && values.attribute ? values.attribute : ""
  );

  const [
    selectedAttributeDisplayName,
    setSelectedAttributeDisplayName,
  ] = useState(
    values && values.attributeName ? values.attributeName : ""
  );

  const [
    editingDynamicContentControl,
    setEditingDynamicContentControl,
  ] = useState(false);

  return (
    <div className="flex-grow-1">
      {editingDynamicContentControl ? (
        <></>
      ) : values &&
        values.dynamicContentItemsValuesArray &&
        values.dynamicContentItemsValuesArray.length > 0 ? (
        <Card className="mb-3">
          <div className="row">
            <div className="col-md-6">
              <ReadOnlyLabel
                label={"Entity"}
                value={convertTypeNumberToEntityDisplayName(
                  getEntityNumberForRegarding(values.entity)
                )}
              />
            </div>
            <div className="col-md-6">
              <ReadOnlyLabel
                label={"Attribute"}
                value={values.attributeName}
              />
            </div>
          </div>
          <small>
            To edit the entity type and attribute values, remove all
            non default dynamic content entries
          </small>
        </Card>
      ) : (
        <Card className="mb-3">
          <CommunicationAttributes
            selectedAttribute={selectedAttribute}
            setSelectedAttribute={(attribute) => {
              setSelectedAttribute(attribute);
              setFieldValue("attribute", attribute);
            }}
            setSelectedAttributeDataTypeCode={(dataTypeCode) => {
              setFieldValue("attributeType", dataTypeCode);
            }}
            setSelectedAttributeLookupEntity={(lookupEntity) => {
              setFieldValue("attributeTarget", lookupEntity);
            }}
            selectedEntity={selectedEntity}
            setSelectedEntity={(entity) => {
              setSelectedEntity(entity);
              setFieldValue(
                "entity",
                getEntityNameForRegarding(entity)
              );
            }}
            setSelectedAttributeDisplayName={(attributeName) => {
              setSelectedAttributeDisplayName(attributeName);
              setFieldValue("attributeName", attributeName);
            }}
            state={state}
            useDynamicContent={true}
          />
        </Card>
      )}
      <CommunicationDesignerDynamicContentList
        {...{
          handleChange,
          handlePicklistChange,
          setFieldValue,
          state,
          values,
        }}
        allowAdd={
          selectedAttribute &&
          selectedEntity &&
          selectedAttributeDisplayName
        }
        stages={stages}
        setEditingDynamicContentControl={
          setEditingDynamicContentControl
        }
        selectedDynamicContentItemIndex={
          selectedDynamicContentItemIndex
        }
        setSelectedDynamicContentItemIndex={
          setSelectedDynamicContentItemIndex
        }
      />
    </div>
  );
}

export default CommunicationDesignerDynamicContentForm;
