import {
  ButtonStyle,
  CommunicationAudienceType,
  TargetedAtTypeNames,
} from "../js/enums";
import Designer from "../components/form/communicationAudience/Designer";
import Export from "../components/form/communicationAudience/Export";
import ReadOnlyRulesAndCount from "../components/form/communicationAudience/ReadOnlyRulesAndCount";
import { CountCommunicationAudienceMembers } from "../js/service";
import Button from "../components/elements/Button";
import { downloadAnnotation, isSameOrLaterDate } from "../js/utility";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups/index";

export const CommunicationAudienceFormStages = [
  {
    title: "Audience",
    id: "audience",
    editOnOverview: false,
    mapValuesOnSubmit: async (dispatch, state, submitValues) => {
      // clear the rule data if the entity type has changed
      if (state.g4c_entitytype !== submitValues.g4c_entitytype) {
        return {
          ...submitValues,
          rule_data: "",
          final_count: 0,
        };
      }

      if (
        state.g4c_communicationaudiencetype !==
        submitValues.g4c_communicationaudiencetype
      ) {
        const count = await new Promise(function (resolve) {
          CountCommunicationAudienceMembers(dispatch, {
            EntityName: state.g4c_entitytype,
            Rules: state.rule_data,
            Type: submitValues.g4c_communicationaudiencetype,
          }).then((response) => {
            resolve(response.data);
          });
        });

        return {
          ...submitValues,
          final_count: count,
        };
      } else {
        return submitValues;
      }
    },
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4c_communicationaudiencetype",
            description: "",
            label: "Type",
            type: "picklist",
            required: true,
            enum: CommunicationAudienceType,
          },
          {
            name: "g4c_entitytype",
            description: "",
            enum: TargetedAtTypeNames,
            label: "Regarding",
            required: true,
            type: "picklist",
          },
        ],
      },
    ],
  },
  {
    title: "Rules",
    id: "rules",
    formClass: "design-stage is-fixed",
    mapValuesOnSubmit: async (dispatch, state, submitValues) => {
      const count = await new Promise(function (resolve) {
        CountCommunicationAudienceMembers(dispatch, {
          EntityName: state.g4c_entitytype,
          Rules: submitValues.rule_data,
          Type: state.g4c_communicationaudiencetype,
        }).then((response) => {
          resolve(response.data);
        });
      });

      return {
        ...submitValues,
        final_count: count,
      };
    },
    sections: [
      {
        name: "",
        fields: [
          {
            name: "rule_data",
            className: "col-12",
            component: Designer,
            overviewRender: (state) => {
              return <ReadOnlyRulesAndCount {...{ state }} />;
            },
            type: "component",
            validate: (value) => {
              const errors = {};
              if(value === ""){
                errors["norule"] = true;
              }
                if (value && value.some((x) => !x.Id)) {
                  value.forEach((x, index) => {
                    if (!x.Id) {
                      errors[`rule-${index}`] = "Required";
                    }
                  });
              }

              return Object.keys(errors).length > 0 ? errors : null;
            },
          },
          {
            name: "final_count",
            hidden: true,
            type: "nvarchar",
          },
        ],
        render: (formFields, index) => {
          return (
            <div key={index} className="mb-3">
              {formFields}
            </div>
          );
        },
      },
    ],
  },
  {
    title: "Marketing List Update Schedule",
    id: "marketing",
    editOnOverview: true,
    renderStage: false,
    displayIfFromState: (state) => state.id,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        fields: [
          {
            name: "g4c_marketinglistid",
            description: "",
            label: "Marketing List",
            lookup: lookups.list.all,
            type: "lookup",
          },
          {
            name: "g4c_updateactive",
            description: "",
            label: "Update Active",
            type: "bit",
          },
          {
            name: "g4c_update",
            description:
              "Defines the frequency, in hours, at which the marketing list is updated based on the communication audience rules",
            label: "Update Rate",
            type: "int",
          },
          {
            name: "g4c_startdate",
            description: "",
            label: "Start Date",
            type: "datetime",
            showTimeSelect: true,
            dateFormat: "dd/MM/yyyy HH:mm",
          },
          {
            name: "g4c_enddate",
            description: "",
            label: "End Date",
            type: "datetime",
            dateFormat: "dd/MM/yyyy HH:mm",
            showTimeSelect: true,
            filterDate: (date, submitValues) => {
              const isValid = submitValues.g4c_startdate
                ? isSameOrLaterDate(
                    date,
                    new Date(submitValues.g4c_startdate)
                  )
                : true;
              return isValid;
            },
            validate: (date, submitValues) => {
              const isValid = submitValues.g4c_startdate
                ? isSameOrLaterDate(date, submitValues.g4c_startdate)
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4c_lastupdated",
            description: "",
            label: "Last Updated",
            type: "datetime",
            showTimeSelect: true,
            dateFormat: "dd/MM/yyyy HH:mm",
            disabled: true,
          },
        ],
      },
    ],
  },
  {
    title: "Exports",
    id: "exports",
    displayIfFromState: (values) =>
      values.g4c_communicationaudienceid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "export",
            className: "col-12",
            component: Export,
            type: "component",
          },
        ],
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "mimetype",
              label: "Type",
              className: "col-name",
            },
            {
              name: "filesize",
              displayFieldValue: (value) =>
                `${value.toLocaleString("en-GB", {
                  useGrouping: "true",
                  maximumFractionDigits: "0",
                })}kb`,
              label: "File Size",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "download",
              displayFieldValue: (_, record) => (
                <Button
                  onClick={() => downloadAnnotation(record.Id)}
                  style={ButtonStyle.Secondary}
                  text="Download"
                />
              ),
              label: "Download",
              className: "col-short-name",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "mimetype",
              label: "Type",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "annotation",
          useSubForm: false,
        },
      },
    ],
  },
];
