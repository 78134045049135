import classNames from "classnames";

function TextControl({ properties }) {
  const {
    backgroundColour,
    borderColour,
    borderThickness,
    borderType,
    columns,
    columnSplit,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    margin,
    padding,
    showOnMobile,
    stackColumns,
    text1,
    text2,
    textAlign,
    textColour,
  } = properties;

  return (
    <table
      className={classNames(
        "full-width zero-margin",
        showOnMobile ? "" : "hide-for-small"
      )}
    >
      <tbody>
        <tr>
          {String(columns) === "2" ? (
            <td style={{ padding: margin }}>
              <table
                className="full-width zero-margin"
                style={{
                  backgroundColor: backgroundColour,
                  borderColor: borderColour,
                  borderStyle: borderType,
                  borderWidth: borderThickness,
                }}
              >
                <tbody>
                  <tr>
                    <td
                      className={classNames(
                        String(stackColumns) === "1" ? "full" : "",
                        String(columnSplit) === "70"
                          ? "seventy-width"
                          : String(columnSplit) === "30"
                          ? "thirty-width"
                          : "half-width"
                      )}
                    >
                      <table
                        align="left"
                        className="zero-margin full-width"
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                color: textColour,
                                fontFamily: fontFamily,
                                fontSize: fontSize,
                                fontWeight: fontWeight,
                                lineHeight: lineHeight,
                                padding: padding,
                                textAlign: textAlign,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: text1,
                              }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      className={classNames(
                        String(stackColumns) === "1" ? "full" : "",
                        String(columnSplit) === "70"
                          ? "thirty-width"
                          : String(columnSplit) === "30"
                          ? "seventy-width"
                          : "half-width"
                      )}
                    >
                      <table
                        align="left"
                        className="zero-margin full-width"
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                color: textColour,
                                fontFamily: fontFamily,
                                fontSize: fontSize,
                                fontWeight: fontWeight,
                                lineHeight: lineHeight,
                                padding: padding,
                                textAlign: textAlign,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: text2,
                              }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          ) : (
            <td style={{ padding: margin }}>
              <table className="full-width zero-margin">
                <tbody>
                  <tr>
                    <td
                      style={{
                        backgroundColor: backgroundColour,
                        borderColor: borderColour,
                        borderStyle: borderType,
                        borderWidth: borderThickness,
                        color: textColour,
                        fontFamily: fontFamily,
                        fontSize: fontSize,
                        fontWeight: fontWeight,
                        lineHeight: lineHeight,
                        padding: padding,
                        textAlign: textAlign,
                      }}
                      dangerouslySetInnerHTML={{ __html: text1 }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
}

export default TextControl;
