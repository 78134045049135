import React, { useState } from "react";
import { AudienceType, ButtonStyle } from "../../../js/enums";
import { GetEntityAttributes } from "../../../js/service";
import {
  getEntityNameForRegarding,
  isRegardingDifferentToListType,
} from "../../../js/utility";
import { useGlobalReducer } from "../../../GlobalContext";
import {
  Button,
  IconCard,
  Loading,
  SelectWithFiltering,
} from "../../elements/_Elements";
import classNames from "classnames";
import {
  convertTypeNumberToEntityDisplayName,
  getPredefinedAttributeDataTypeCodes,
  getPredefinedAttributeDataTypeCodesForDynamicContent,
  getPredefinedAttributeLookupEntityForDynamicContent,
  getPredefinedAttributes,
  getPredefinedAttributesForDynamicContent,
} from "./Helpers";

function CommunicationAttributes({
  bodyText,
  setSelectedAttribute,
  setSelectedAttributeDataTypeCode,
  setSelectedAttributeDisplayName,
  setSelectedAttributeLookupEntity,
  selectedEntity,
  setSelectedEntity,
  state,
  useDynamicContent = false,
}) {
  const { g4c_audiencetype, g4c_listtype, g4c_regardingtype } = state;
  const targetAtEntityNumber =
    String(state.g4c_listtype) === "1" ? 6 : 3; //6 for account, 3 for contact

  const [selectedBaseAttribute, setSelectedBaseAttribute] =
    useState("");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState("");

  const globalDispatch = useGlobalReducer();
  const fetchDataForEntity = () => {
    const fetchData = async () => {
      try {
        //Either get the attributes for the targeted at entity,
        //or the attributes for the regarding entity
        const [attributeResponse] = await Promise.all([
          selectedEntity === targetAtEntityNumber
            ? GetEntityAttributes(
                globalDispatch,
                getEntityNameForRegarding(targetAtEntityNumber)
              )
            : String(state["g4c_regardingtype"]) !==
              String(targetAtEntityNumber)
            ? GetEntityAttributes(
                globalDispatch,
                getEntityNameForRegarding(state["g4c_regardingtype"])
              )
            : [],
        ]);
        setFormData({
          attributes:
            attributeResponse && attributeResponse.data
              ? attributeResponse.data
              : [],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    fetchData();
  };

  const includeRegardingType =
    String(g4c_audiencetype) === String(AudienceType["A/B Testing"])
      ? false
      : isRegardingDifferentToListType(
          g4c_listtype,
          g4c_regardingtype
        );

  const handleSelectChange = (event) => {
    if (event && event.value && event.value !== "") {
      const { value } = event;
      let attributeName = "";
      let attributeDisplayName = "";
      let attributeLookupEntity = "";
      let attributeDataTypeCode = "";

      //Check the value against the predefined fields in case that was selected
      let matchedPredefinedField = Object.entries(
        useDynamicContent
          ? getPredefinedAttributesForDynamicContent(selectedEntity)
          : getPredefinedAttributes(selectedEntity)
      ).find(([_, val]) => val === value);

      let matchedPredefinedFieldName = matchedPredefinedField?.[1];

      let matchedPredefinedFieldDisplayName =
        matchedPredefinedField?.[0];

      let matchedPredefinedFieldDataTypeCode = Object.entries(
        useDynamicContent
          ? getPredefinedAttributeDataTypeCodesForDynamicContent(
              selectedEntity
            )
          : getPredefinedAttributeDataTypeCodes(selectedEntity)
      ).find(([key, _]) => key === value)?.[1];

      if (
        matchedPredefinedFieldName &&
        matchedPredefinedFieldDisplayName &&
        String(matchedPredefinedFieldDataTypeCode) &&
        matchedPredefinedFieldDataTypeCode >= 0
      ) {
        attributeName = matchedPredefinedFieldName;
        attributeDisplayName = matchedPredefinedFieldDisplayName;
        attributeDataTypeCode = matchedPredefinedFieldDataTypeCode;
        //If its a lookup data type then also get the lookup entity name
        if (
          useDynamicContent &&
          matchedPredefinedFieldDataTypeCode === 6
        ) {
          attributeLookupEntity = Object.entries(
            getPredefinedAttributeLookupEntityForDynamicContent(
              selectedEntity
            )
          ).find(([key, _]) => key === value)?.[1];
        }
      }
      //Otherwise check it against the attributes in the formData
      else {
        let filteredAttributes = formData.attributes.filter(
          (att) => att.AttributeId === value
        );
        if (filteredAttributes.length === 1) {
          attributeName = filteredAttributes[0].Name;
          attributeDisplayName = filteredAttributes[0].DisplayName;
          attributeLookupEntity = filteredAttributes[0].LookupEntity;
          attributeDataTypeCode = filteredAttributes[0].DataTypeCode;
        }
      }

      //Add regarding_ to the front if its the regarding entity
      attributeName =
        selectedEntity === targetAtEntityNumber
          ? attributeName
          : "regarding_" + attributeName;
      setSelectedAttribute(attributeName);
      setSelectedBaseAttribute(value);
      if (setSelectedAttributeDisplayName) {
        setSelectedAttributeDisplayName(attributeDisplayName);
      }
      if (setSelectedAttributeLookupEntity) {
        setSelectedAttributeLookupEntity(attributeLookupEntity);
      }
      if (setSelectedAttributeDataTypeCode) {
        setSelectedAttributeDataTypeCode(attributeDataTypeCode);
      }
    } else {
      setSelectedAttribute("");
      setSelectedBaseAttribute("");
      if (setSelectedAttributeDisplayName) {
        setSelectedAttributeDisplayName("");
      }
      if (setSelectedAttributeLookupEntity) {
        setSelectedAttributeLookupEntity("");
      }
      if (setSelectedAttributeDataTypeCode) {
        setSelectedAttributeDataTypeCode("");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {includeRegardingType && (
            <>
              <small>Select an entity type</small>
              <div className="row mb-3">
                <div key={1} className="col-md-6">
                  <IconCard
                    containerClass={classNames(
                      "border",
                      "rounded",
                      "cursor-pointer",
                      selectedEntity === targetAtEntityNumber
                        ? "border-primary"
                        : ""
                    )}
                    onClick={() => {
                      setSelectedEntity(targetAtEntityNumber);
                      setFormData(null);
                    }}
                  >
                    {convertTypeNumberToEntityDisplayName(
                      targetAtEntityNumber
                    )}
                  </IconCard>
                </div>
                <div key={2} className="col-md-6">
                  <IconCard
                    containerClass={classNames(
                      "border",
                      "rounded",
                      "cursor-pointer",
                      selectedEntity === state["g4c_regardingtype"]
                        ? "border-primary"
                        : ""
                    )}
                    onClick={() => {
                      setSelectedEntity(state["g4c_regardingtype"]);
                      setFormData(null);
                    }}
                  >
                    {convertTypeNumberToEntityDisplayName(
                      state["g4c_regardingtype"]
                    )}
                  </IconCard>
                </div>
              </div>
            </>
          )}
          {bodyText && <small>{bodyText}</small>}
          <SelectWithFiltering
            label={"Attribute"}
            name={"Attribute"}
            options={
              formData && formData.attributes
                ? formData.attributes
                    .map((att) => {
                      return {
                        Key: att.AttributeId,
                        Value: att.DisplayName
                          ? att.DisplayName
                          : att.Name,
                      };
                    })
                    .sort((a, b) => a.Value.localeCompare(b.Value))
                : Object.entries(
                    useDynamicContent
                      ? getPredefinedAttributesForDynamicContent(
                          selectedEntity
                        )
                      : getPredefinedAttributes(selectedEntity)
                  ).map(([Name, Number]) => ({
                    Key: Number,
                    Value: Name,
                  }))
            }
            onChange={(event) => handleSelectChange(event)}
            placeholder={"Please select"}
            value={selectedBaseAttribute}
          />
          {!formData && (
            <Button
              className={"mt-3"}
              text={"Fetch remaining attributes"}
              style={ButtonStyle.OutlineSecondary}
              onClick={() => {
                fetchDataForEntity();
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default CommunicationAttributes;
