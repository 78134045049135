import classNames from "classnames";

function SocialFollowControl({ properties }) {
  const {
    backgroundColour,
    borderColour,
    borderThickness,
    borderType,
    buttonAlign,
    buttonFontFamily,
    buttonFontSize,
    buttonFontWeight,
    buttonMargin,
    buttonShowText,
    buttonTextColour,
    margin,
    padding,
    socialPlatforms,
    stackOnMobile,
  } = properties;

  const getPlatforms = (platformsString) =>
    platformsString ? platformsString.split(",") : [];

  const getPairedPlatforms = (platformsString) => {
    const pairedPlatforms = [];

    const platforms = getPlatforms(platformsString);
    for (let i = 0; i < platforms.length; i += 2) {
      pairedPlatforms.push({
        First: platforms[i],
        Second: platforms[i + 1] || null, // If there's no second platform, set it to null
      });
    }

    return pairedPlatforms;
  };

  return (
    <div>
      <div
        className={classNames(stackOnMobile ? "hide-for-small" : "")}
      >
        <table className="full-width zero-margin">
          <tbody>
            <tr>
              <td style={{ padding: margin }}>
                <table
                  className="full-width zero-margin"
                  style={{
                    backgroundColor: backgroundColour,
                    borderStyle: borderType,
                    borderWidth: borderThickness,
                    borderColor: borderColour,
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: padding,
                          textAlign: buttonAlign,
                        }}
                      >
                        <table
                          className="zero-margin"
                          style={{ display: "inline-block" }}
                        >
                          <tbody>
                            <tr>
                              {getPlatforms(socialPlatforms).map(
                                (socialPlatform, index) => {
                                  const height =
                                    properties[
                                      socialPlatform + "Height"
                                    ];
                                  const src =
                                    properties[
                                      socialPlatform + "Src"
                                    ];
                                  const text =
                                    properties[
                                      socialPlatform + "Text"
                                    ];
                                  const url =
                                    properties[
                                      socialPlatform + "Url"
                                    ];
                                  const width =
                                    properties[
                                      socialPlatform + "Width"
                                    ];

                                  return (
                                    <td
                                      key={index}
                                      className="center width-auto"
                                    >
                                      <table className="full-width zero-margin">
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                padding: buttonMargin,
                                              }}
                                            >
                                              <table className="zero-margin">
                                                <tbody>
                                                  <tr>
                                                    <td className="center">
                                                      {src && (
                                                        <a
                                                          href={url}
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <img
                                                            alt={text}
                                                            src={src}
                                                            className="social-media-icon"
                                                            width={
                                                              width
                                                            }
                                                            height={
                                                              height
                                                            }
                                                            style={{
                                                              cursor:
                                                                "pointer",
                                                              float:
                                                                "none",
                                                              height:
                                                                height +
                                                                "px",
                                                              marginLeft:
                                                                "auto",
                                                              marginRight:
                                                                "auto",
                                                              width:
                                                                width +
                                                                "px",
                                                            }}
                                                          />
                                                        </a>
                                                      )}
                                                    </td>
                                                  </tr>
                                                  {buttonShowText && (
                                                    <tr>
                                                      <td className="center">
                                                        {text && (
                                                          <a
                                                            href={url}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="text-center"
                                                            style={{
                                                              color:
                                                                buttonTextColour,
                                                              cursor:
                                                                "pointer !important",
                                                              fontFamily:
                                                                buttonFontFamily,
                                                              fontSize:
                                                                buttonFontSize,
                                                              fontWeight:
                                                                buttonFontWeight,
                                                            }}
                                                          >
                                                            {text}
                                                          </a>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className={classNames(stackOnMobile ? "show-for-small" : "")}
        style={{ display: stackOnMobile ? "block" : "none" }}
      >
        <table className="full-width zero-margin">
          <tbody>
            <tr>
              <td style={{ padding: margin }}>
                <table
                  className="full-width zero-margin"
                  style={{
                    backgroundColor: backgroundColour,
                    borderStyle: borderType,
                    borderWidth: borderThickness,
                    borderColor: borderColour,
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: padding,
                          textAlign: buttonAlign,
                        }}
                      >
                        <table className="zero-margin">
                          <tbody>
                            {getPairedPlatforms(socialPlatforms).map(
                              (paired, index) => {
                                const { First, Second } = paired;

                                const firstHeight =
                                  properties[First + "Height"];
                                const firstSrc =
                                  properties[First + "Src"];
                                const firstText =
                                  properties[First + "Text"];
                                const firstUrl =
                                  properties[First + "Url"];
                                const firstWidth =
                                  properties[First + "Width"];

                                const secondHeight = Second
                                  ? properties[Second + "Height"]
                                  : null;
                                const secondSrc = Second
                                  ? properties[Second + "Src"]
                                  : null;
                                const secondText = Second
                                  ? properties[Second + "Text"]
                                  : null;
                                const secondUrl = Second
                                  ? properties[Second + "Url"]
                                  : null;
                                const secondWidth = Second
                                  ? properties[Second + "Width"]
                                  : null;

                                return (
                                  <tr key={index}>
                                    {Second !== null ? (
                                      <>
                                        <td
                                          className="half-width"
                                          style={{
                                            padding: buttonMargin,
                                          }}
                                        >
                                          <table className="zero-margin">
                                            <tbody>
                                              <tr>
                                                <td className="center">
                                                  {firstSrc && (
                                                    <a
                                                      href={firstUrl}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      <img
                                                        alt={
                                                          firstText
                                                        }
                                                        src={firstSrc}
                                                        className="social-media-icon"
                                                        width={
                                                          firstWidth
                                                        }
                                                        height={
                                                          firstHeight
                                                        }
                                                        style={{
                                                          cursor:
                                                            "pointer",
                                                          float:
                                                            "none",
                                                          height:
                                                            firstHeight +
                                                            "px",
                                                          marginLeft:
                                                            "auto",
                                                          marginRight:
                                                            "auto",
                                                          width:
                                                            firstWidth +
                                                            "px",
                                                        }}
                                                      />
                                                    </a>
                                                  )}
                                                </td>
                                              </tr>
                                              {buttonShowText && (
                                                <tr>
                                                  <td className="center">
                                                    {firstText && (
                                                      <a
                                                        href={
                                                          firstUrl
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-center"
                                                        style={{
                                                          color:
                                                            buttonTextColour,
                                                          cursor:
                                                            "pointer !important",
                                                          fontFamily:
                                                            buttonFontFamily,
                                                          fontSize:
                                                            buttonFontSize,
                                                          fontWeight:
                                                            buttonFontWeight,
                                                        }}
                                                      >
                                                        {firstText}
                                                      </a>
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </td>
                                        <td
                                          className="half-width"
                                          style={{
                                            padding: buttonMargin,
                                          }}
                                        >
                                          <table className="zero-margin">
                                            <tbody>
                                              <tr>
                                                <td className="center">
                                                  {secondSrc && (
                                                    <a
                                                      href={secondUrl}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      <img
                                                        alt={
                                                          secondText
                                                        }
                                                        src={
                                                          secondSrc
                                                        }
                                                        className="social-media-icon"
                                                        width={
                                                          secondWidth
                                                        }
                                                        height={
                                                          secondHeight
                                                        }
                                                        style={{
                                                          cursor:
                                                            "pointer",
                                                          float:
                                                            "none",
                                                          height:
                                                            secondHeight +
                                                            "px",
                                                          marginLeft:
                                                            "auto",
                                                          marginRight:
                                                            "auto",
                                                          width:
                                                            secondWidth +
                                                            "px",
                                                        }}
                                                      />
                                                    </a>
                                                  )}
                                                </td>
                                              </tr>
                                              {buttonShowText && (
                                                <tr>
                                                  <td className="center">
                                                    {secondText && (
                                                      <a
                                                        href={
                                                          secondUrl
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-center"
                                                        style={{
                                                          color:
                                                            buttonTextColour,
                                                          cursor:
                                                            "pointer !important",
                                                          fontFamily:
                                                            buttonFontFamily,
                                                          fontSize:
                                                            buttonFontSize,
                                                          fontWeight:
                                                            buttonFontWeight,
                                                        }}
                                                      >
                                                        {secondText}
                                                      </a>
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </td>
                                      </>
                                    ) : (
                                      <td
                                        colSpan="2"
                                        style={{
                                          padding: buttonMargin,
                                        }}
                                      >
                                        <table className="zero-margin">
                                          <tbody>
                                            <tr>
                                              <td className="center">
                                                {firstSrc && (
                                                  <a
                                                    href={firstUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    <img
                                                      alt={firstText}
                                                      src={firstSrc}
                                                      className="social-media-icon"
                                                      width={
                                                        firstWidth
                                                      }
                                                      height={
                                                        firstHeight
                                                      }
                                                      style={{
                                                        cursor:
                                                          "pointer",
                                                        float: "none",
                                                        height:
                                                          firstHeight +
                                                          "px",
                                                        marginLeft:
                                                          "auto",
                                                        marginRight:
                                                          "auto",
                                                        width:
                                                          firstWidth +
                                                          "px",
                                                      }}
                                                    />
                                                  </a>
                                                )}
                                              </td>
                                            </tr>
                                            {buttonShowText && (
                                              <tr>
                                                <td className="center">
                                                  {firstText && (
                                                    <a
                                                      href={firstUrl}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      className="text-center"
                                                      style={{
                                                        color:
                                                          buttonTextColour,
                                                        cursor:
                                                          "pointer !important",
                                                        fontFamily:
                                                          buttonFontFamily,
                                                        fontSize:
                                                          buttonFontSize,
                                                        fontWeight:
                                                          buttonFontWeight,
                                                      }}
                                                    >
                                                      {firstText}
                                                    </a>
                                                  )}
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </td>
                                    )}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SocialFollowControl;
